import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as LogRocket from 'logrocket';

const VALID_ID_PATTERN = /^[a-zA-Z0-9]/;

@Injectable({ providedIn: 'root' })
export class LogRocketService {
    private logRocketId = environment.logRocketId;
    private initialised = false;

    addLogRocket(): void {
        if (!this.logRocketId || !VALID_ID_PATTERN.test(this.logRocketId)) {
            return;
        }

        try {
            LogRocket.init(this.logRocketId, {
                dom: {
                    textSanitizer: false,
                    inputSanitizer: true
                },
                network: {
                    requestSanitizer: (request) => {
                        // Remove access token header and request payload.
                        request.headers.Authorization = null;
                        request.body = null;

                        // Below can be used to target our API calls if we needed different sanitisation.
                        //const url = request.url.toLowerCase().trim();
                        //const isOurs = url.startsWith('api/') ||
                        //    (url.indexOf(location.host) >= 0 && url.indexOf('/api/') >= 0);

                        return request;
                    },
                    responseSanitizer: () => null
                }
            });
            this.initialised = true;
        } catch (ex) { }
    }

    setUserDetails(userId: string, email: string, name: string, startNewSession: boolean): void {
        if (!this.initialised) {
            return;
        }

        try {
            if (startNewSession) {
                LogRocket.startNewSession();
            }
            LogRocket.identify(userId, {
                name,
                email: (email || '')
            });
        } catch (ex) { }
    }
}
