// models
import { Address } from 'app/portal/modules/inputs/models/address.model';
import { Tag } from './tag.model';
import { DataCheckResults } from 'app/portal/modules/entity-list/models/data-check-results.model';

// enums
import { EntityUserStatus, getEntityUserStatusTitle } from 'app/portal/modules/entity-list/enumerations/entity-user-status.enum';
import { EntityApprovalStatus } from 'app/portal/modules/entity-list/enumerations/entity-approval-status.enum';
import { EntityStatus } from 'app/portal/modules/entity-list/enumerations/entity-status.enum';
import { LozengeVariant } from 'app/connect/enums/lozenge-variant.enum';

export class EntitySummary {
    id: string;
    name: string;
    firstName: string;
    middleName: string;
    surname: string;
    dateCreated: string;
    emailAddress: string;
    phoneNumber: string;
    reference: string;
    registrationCode: string;
    status: EntityStatus;
    userStatus: EntityUserStatus;
    inPerson: boolean;
    noContact: boolean;
    approvalStatus: EntityApprovalStatus;
    userGroupId: string;
    entityTags: Tag[];
    entityFolderIds: string[];
    address: Address;
    dateOfBirth: string;
    journeyPercentages: Map<string, number>;
    createdByUserId: string;
    createdByUserName: string;
    percentageComplete: number;
    senderTelephoneNumberId?: number;

    // Items for UI toggles
    selected: boolean;
    menuOpen: boolean;
    section: string;

    // Data Checks Results
    dataCheckResults: DataCheckResults;
}

export function getEntityStatusLozengeText(entity: EntitySummary): string {
    if (entity.status === EntityStatus.Expired) {
        return 'Expired';
    }

    if (entity.inPerson) {
        return 'In Person';
    }

    switch (entity.userStatus) {
        case EntityUserStatus.Active:
            return 'Active';
        case EntityUserStatus.BouncedInvite:
            return 'Bounced Invite';
        case EntityUserStatus.Invited:
            return 'Invited';
        case EntityUserStatus.New:
            return 'No contact';
    }
}

export function getEntityStatusLozengeVariant(entity: EntitySummary): LozengeVariant {
    if(entity.status === EntityStatus.Expired) {
        return LozengeVariant.Gray;
    }

    if(entity.inPerson) {
        return LozengeVariant.LightBlue;
    }

    switch(entity.userStatus) {
        case EntityUserStatus.Active:
            return LozengeVariant.Green;
        case EntityUserStatus.BouncedInvite:
            return LozengeVariant.Red;
        case EntityUserStatus.Invited:
            return LozengeVariant.Amber;
        case EntityUserStatus.New:
            return LozengeVariant.Black;
    }
}

export function getEntityStatusTitle(
    entityStatus: EntityStatus,
    entityUserStatus: EntityUserStatus,
    inPerson: boolean
) {
    // When the entity status is expired we want to ignore the entity user status
    // and directly return expired
    if (entityStatus === EntityStatus.Expired) {
        return 'Expired';
    }

    if (inPerson) {
        return 'In Person';
    }

    return getEntityUserStatusTitle(entityUserStatus);
}