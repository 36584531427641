import { Injectable } from '@angular/core';

// ngrx
import { Store } from '@ngrx/store';

// store
import * as fromApp from 'app/store';
import * as fromUserInterface from 'app/store/actions/user-interface.actions';

// utilities
import { getBoolean } from 'app/shared/utilities/boolean-utilities';

// models
import { RefreshTokenRequest, TokenResponse } from 'app/shared/models';
import { LogoutRequest } from 'app/shared/models/authentication/logout-request.model';

@Injectable()
export class AuthenticationTokenService {

    private tokenName: string = 'token';
    private refreshTokenName: string = 'refresh-token';
    private clientIdName: string = 'clientId';
    private userIdName: string = 'userId';
    private hideHeaderName = 'hideHeader';
    private readOnlyAccessName = 'readOnly';
    private showClientLogoName = 'show-client-logo';
    private showNavigationMenuName = 'show-navigation-menu';
    private showUserProfileButtonName = 'show-user-profile-button';
    private userGroupIdName = 'userGroupId';
    private emailName = 'email';
    private expiresTimestamp = 'expiresTimestamp';
    private storage: Storage = sessionStorage;

    constructor(
        private appStore: Store<fromApp.State>) { }

    getToken(): string {
        return this.storage.getItem(this.tokenName);
    }

    getRefreshToken(): string {
        return this.storage.getItem(this.refreshTokenName);
    }

    clear(): void {
        this.appStore.dispatch(new fromUserInterface.ClearSessionTimeout());
        this.storage.removeItem(this.tokenName);
        this.storage.removeItem(this.refreshTokenName);
        this.storage.removeItem(this.userIdName);
        this.storage.removeItem(this.clientIdName);
        this.storage.removeItem(this.hideHeaderName);
        this.storage.removeItem(this.readOnlyAccessName);
        this.storage.removeItem(this.userGroupIdName);
        this.storage.removeItem(this.showClientLogoName);
        this.storage.removeItem(this.showNavigationMenuName);
        this.storage.removeItem(this.showUserProfileButtonName);
    }

    setAuthToken(newToken: TokenResponse): void {
        this.storage.setItem(this.tokenName, newToken.auth_token);
        this.storage.setItem(this.refreshTokenName, newToken.refresh_token);
        this.storage.setItem(this.userIdName, newToken.id);

        // Add the expiry (seconds) to the timestamp (milliseconds)
        this.storage.setItem(this.expiresTimestamp, (new Date().getTime() + (newToken.expires_in * 1000)).toString());

        this.appStore.dispatch(new fromUserInterface.StartSessionTimeout());
    }

    clientId(): string {
        return this.storage.getItem(this.clientIdName);
    }

    userId(): string {
        return this.storage.getItem(this.userIdName);
    }

    isLoggedIn(): boolean {
        return this.getToken() != null;
    }

    getLoginUrl(): string {
        return '/auth-v2/login';
    }

    setActiveClient(clientId: string): void {
        this.storage.setItem(this.clientIdName, clientId);
    }

    hideHeader(): boolean {
         return this.storage.getItem(this.hideHeaderName)?.toLowerCase() === 'true';
    }

    setHideHeader(hideHeader: boolean): void {
        this.storage.setItem(this.hideHeaderName, hideHeader ? 'true' : 'false');
    }

    readOnlyAccess(): boolean {
        return this.storage.getItem(this.readOnlyAccessName)?.toLowerCase() === 'true';
    }

    setReadOnlyAccess(readOnlyAccess: boolean): void {
        this.storage.setItem(this.readOnlyAccessName, readOnlyAccess ? 'true' : 'false');
    }

    userGroupId(): string {
        return this.storage.getItem(this.userGroupIdName);
    }

    setUserGroup(userGroupId: string): void {
        this.storage.setItem(this.userGroupIdName, userGroupId);
    }

    get showClientLogo(): boolean {
        return getBoolean(this.storage.getItem(this.showClientLogoName), true);
    }

    set showClientLogo(value: boolean) {
        this.storage.setItem(this.showClientLogoName, JSON.stringify(value));
    }

    get showNavigationMenu(): boolean {
        return getBoolean(this.storage.getItem(this.showNavigationMenuName), true);
    }

    set showNavigationMenu(value: boolean) {
        this.storage.setItem(this.showNavigationMenuName, JSON.stringify(value));
    }

    get showUserProfileButton(): boolean {
        return getBoolean(this.storage.getItem(this.showUserProfileButtonName), true);
    }

    set showUserProfileButton(value: boolean) {
        this.storage.setItem(this.showUserProfileButtonName, JSON.stringify(value));
    }

    tokenHasExpired(): boolean {
        const expires = parseInt(this.storage.getItem(this.expiresTimestamp), 10);
        const current = new Date().getTime();
        return expires < current;
    }

    getRefreshTokenRequest(): RefreshTokenRequest {
        return new RefreshTokenRequest(
            this.userId(),
            this.getRefreshToken(),
            this.getToken());
    }

    getLogoutRequest(): LogoutRequest {
        return new LogoutRequest(
            this.userId(),
            this.getRefreshToken());
    }

    setUserEmail(email: string) {
        this.storage.setItem(this.emailName, email);
    }

    getUserEmail(): string {
        return this.storage.getItem(this.emailName);
    }
}