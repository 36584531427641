<app-authentication-layout id="enter-pin" fxFlex pageTitle="Your PIN" data-auto="enter-pin">
    <app-loading-card *ngIf="working"></app-loading-card>
    <div [formGroup]="form" fxLayout="column" (keydown.enter)="onEnterPressed()">
        <p class="font-size-s">
            Now enter your PIN below.
        </p>
        <div fxLayout="column">
            <div class="margin-bottom-10">
                <app-authentication-code-entry #codeEntry length="4"
                                               spacerInterval="2"
                                               (valueChanged)="onValueChanged($event)"
                                               [numericOnly]="true"
                                               [asPassword]="true"
                                               style="max-width: 250px; display: block;"></app-authentication-code-entry>
            </div>
            <p class="mat-error" *ngIf="authenticationMessage" data-auto="pin-error-message">
                {{authenticationMessage}}
            </p>
            <button class="btn large primary dark"
                    [disabled]="working || form.invalid"
                    (click)="onEnterPressed()" data-auto="login-button">
                Get me in
            </button>
            <div class="margin-top-24 font-size-xs">
                Can't remember your login details?
                <a (click)="navigation.gotoForgotLoginDetails()" class="text-link font-size-inherit">
                    Click here
                </a>
            </div>
        </div>
    </div>
</app-authentication-layout> 