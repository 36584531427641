import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// store
import { reducers, effects } from './store';

// dialogs
import { SetTemporaryPasswordDialogComponent } from './dialogs/set-temporary-password-dialog/set-temporary-password-dialog.component';
import { TermsDialogComponent } from 'app/authentication-v2/components/terms-dialog/terms-dialog.component';
import { TimeoutExplanationDialogComponent } from './dialogs/timeout-explanation-dialog/timeout-explanation-dialog.component';

// services
import { PasswordService } from './services/password.service';
import { TermsService } from 'app/authentication-v2/services/terms.service';
import { UserService } from 'app/authentication-v2/services/user.service';
import { UserProfileService } from 'app/authentication-v2/services/user-profile.service';

// modules
import { AuthenticationV2RoutingModule } from './authentication-v2-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { ConnectModule } from 'app/connect/connect.module';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

// components
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TermsAcceptanceFormComponent } from './components/terms-acceptance-form/terms-acceptance-form.component';
import { AuthenticationLayoutComponent } from './components/authentication-layout/authentication-layout.component';
import { CodeEntryComponent } from './components/code-entry/code-entry.component';
import { SetPinComponent } from './components/set-pin/set-pin.component';
import { MobileNumberComponent } from './components/mobile-number/mobile-number.component';
import { EnterPinComponent } from './components/enter-pin/enter-pin.component';
import { ForgotLoginDetailsComponent } from './components/forgot-login-details/forgot-login-details.component';
import { ContactAdministratorComponent } from './components/contact-administrator/contact-administrator.component';
import { UserProfileDialogComponent } from './dialogs';
import { OtpPageComponent } from 'app/authentication-v2/components/otp-page/otp-page.component';
import { OtpCheckDialogComponent } from 'app/authentication-v2/dialogs/otp-check-dialog/otp-check-dialog.component';
import { ResetSecurityComponent } from 'app/authentication-v2/components/reset-security/reset-security.component';
import { WhatToResetComponent } from 'app/authentication-v2/components/what-to-reset/what-to-reset.component';
import { SetTemporaryPinDialogComponent } from 'app/authentication-v2/dialogs/set-temporary-pin-dialog/set-temporary-pin-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        AuthenticationV2RoutingModule,
        StoreModule.forFeature(StoreNames.authenticationV2, reducers),
        EffectsModule.forFeature(effects),
        SharedModule,
        ConnectModule
    ],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        TermsAcceptanceFormComponent,
        AuthenticationLayoutComponent,
        SetTemporaryPasswordDialogComponent,
        TimeoutExplanationDialogComponent,
        TermsDialogComponent,
        CodeEntryComponent,
        SetPinComponent,
        MobileNumberComponent,
        EnterPinComponent,
        ForgotLoginDetailsComponent,
        ContactAdministratorComponent,
        OtpPageComponent,
        OtpCheckDialogComponent,
        ResetSecurityComponent,
        WhatToResetComponent,
        UserProfileDialogComponent,
        SetTemporaryPinDialogComponent
    ],
    entryComponents: [
        TermsAcceptanceFormComponent,
        SetTemporaryPasswordDialogComponent,
        TimeoutExplanationDialogComponent,
        TermsDialogComponent,
        OtpCheckDialogComponent
    ],
    providers: [
        PasswordService,
        TermsService,
        UserService,
        UserProfileService
    ]
})
export class AuthenticationV2Module { }
