import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';
import * as fromAuthV2 from 'app/authentication-v2/store';
import * as fromRoot from 'app/store';

// services
import { ModuleService } from 'app/shared/services/module.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';
import { AuthenticationV2Service } from 'app/authentication-v2/services/authentication-v2.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { NavigationService } from 'app/shared/services/navigation.service';
import { SessionStateService } from 'app/shared/services/session-state.service';

// models
import { ClientTokenResponse } from 'app/models/client-token-response.model';
import { TokenResponse } from 'app/shared/models';

// enums
import { MagicLinkTarget } from 'app/authentication-v2/enumerations/magic-link-target.enum';
import { ClientType } from 'app/connect-management/modules/clients/enumerations/client-type.enum';

// components
import { ChargeableModeWarningDialogComponent } from 'app/connect/components/chargeable-mode-warning-dialog/chargeable-mode-warning-dialog.component';

@Injectable()
export class ApplicationEffects {

    setUser$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetUser),
        map((action) => {
            ModuleService.populate(action.user);
            // if we know the client from the token, then set it
            const clientId = this.authenticationTokenService.clientId();

            // Select from the token if the client is not locked
            if (clientId && action.user.clients?.find(client => client.id === clientId)?.locked === false) {
                return fromConnect.SelectClient({ clientId, performRedirect: false, setServiceFromUrl: true });
            } else if (action.user.isSuperUser) {
                // if the user is a super user, open the client selector
                return fromConnect.OpenClientSelector();
            } else if (action.user.clients.filter(client => !client.locked).length === 1) {
                // if the user has only one client, auto select the client
                return fromConnect.SelectClient({ clientId: action.user.clients.find(client => !client.locked).id, performRedirect: true, setServiceFromUrl: false });
            } else {
                // else the user has more than one entity, more than one client, or a mixture of both, open the client selector
                return fromConnect.OpenClientSelector();
            }
        })));

    selectClient$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SelectClient),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const client = state.user.clients.find(
                (c) => c.id === action.clientId
            );

            if (!state.user.isSuperUser && (!state.user.isResellerSuperUser || client.type === ClientType.Reseller)) {
                if (client.userGroups?.length > 1) {
                    return fromConnect.OpenUserGroupSelector();
                }

                if (client.userGroups?.length === 1) {
                    return fromConnect.SetUserGroup({
                        clientId: client.id,
                        userGroup: client.userGroups[0],
                        performRedirect: action.performRedirect,
                        setServiceFromUrl: action.setServiceFromUrl,
                    });
                }
            }

            return fromConnect.SetAndPopulateClient({
                clientId: client.id,
                performRedirect: action.performRedirect,
                setServiceFromUrl: action.setServiceFromUrl,
            });
        })));

    setAndPopulateClient$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetAndPopulateClient),
        switchMap(action => this.authV2Service.setAndPopulateClient(action.clientId).pipe(
            map((clientTokenResponse: ClientTokenResponse) => fromConnect.SetAndPopulateClientSuccess({
                        client: clientTokenResponse.client,
                        performRedirect: action.performRedirect,
                        setServiceFromUrl: action.setServiceFromUrl,
                        token: clientTokenResponse.token})),
            catchError(() => of(fromConnect.SetClientFail()))
        ))
    ));

    setAndPopulateClientSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetAndPopulateClientSuccess),
        switchMap(action => {
            if (action.token?.password_reset_required) {
                this.navigationService.gotoSetPassword(action.token.reset_password_token, this.authenticationTokenService.getUserEmail(), true, true);
                return [];
            }

            ModuleService.setClient(action.client);
            this.authenticationTokenService.setActiveClient(action.client.id);
            this.authenticationTokenService.setAuthToken(action.token);
            const actions: any[] = [
                fromConnect.SetBranding({ branding: action.client.branding}),
                fromConnect.SetTokenSuccess({ performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl })
            ];

            if (ModuleService.isChargeableMode) {
                actions.push(fromConnect.DisplayChargeableModeWarning());
            }

            return actions;
        }
    )));

    setClientFail$ = createEffect(() =>  this.actions$.pipe(
        ofType(fromConnect.SetClientFail),
        tap(() => this.alertService.error('An error occurred during client selection.')
        )
    ), { dispatch: false });

    setClientTokenSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetTokenSuccess),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState), this.rootStore$.select(fromRoot.isMobileDevice)),
        switchMap(([action, state, isMobileDevice]) => {
            if(isMobileDevice && !state.user.isSuperUser) {
                this.router.navigate(['/portal/create-invite/launch']);
                return [];
            }

            const returnActions: Action[] = [
                this.authenticationTokenService.hideHeader()
                    ? fromConnect.HideHeader()
                    : fromConnect.ShowHeader(),
                fromConnect.SetReadOnlyAccess({
                    readOnlyAccess:
                        this.authenticationTokenService.readOnlyAccess(),
                }),
                fromConnect.SetClientLogoVisibility({
                    visible: this.authenticationTokenService.showClientLogo,
                }),
                fromConnect.SetNavigationMenuVisibility({
                    visible: this.authenticationTokenService.showNavigationMenu,
                }),
                fromConnect.SetUserProfileButtonVisibility({
                    visible:
                        this.authenticationTokenService.showUserProfileButton,
                }),
                fromConnect.SetApplications({
                    modules: state.client?.userModules ?? [],
                    performRedirect: action.performRedirect,
                    setServiceFromUrl: action.setServiceFromUrl,
                }),
            ];

            return returnActions;
        })
    ));

    setApplications$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetApplications),
        filter(action => action.performRedirect || action.setServiceFromUrl),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        switchMap(([action, state]) => {
            const applications = state.applications;

            if (!applications.length) {
                return [];
            }

            if (action.performRedirect) {
                const application = applications[0];
                return [
                    fromConnect.SelectApplication({ applicationId: application.id, performRedirect: true })
                ];
            }

            return [fromConnect.SetApplicationFromUrl()];
        })
    ));

    setApplicationFromUrl$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetApplicationFromUrl),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        switchMap(([, state]) => {
            const url = window.location.href.replace(window.location.origin, '');
            const service = state.client.userModules.find(m => m.parentId && m.url && url.startsWith(m.url));

            if (service) {
                const application = state.client.userModules.find(m => m.id === service.parentId);

                return [
                    fromConnect.SelectApplication({ applicationId: application.id, performRedirect: false }),
                    fromConnect.SelectService({ serviceId: service.id, performRedirect: false }),
                ];
            }

            if (url.length < 3 || url.startsWith('/auth')) {
                const application = state.applications[0];

                return [
                    fromConnect.SelectApplication({ applicationId: application.id, performRedirect: true })
                ];
            }

            return [];
        })
    ));

    selectApplication$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SelectApplication),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const application = state.applications.find(m => m.id === action.applicationId);
            return fromConnect.SetApplication({ application, performRedirect: action.performRedirect });
        })));

    setApplication$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetApplication),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const services =
                state.client?.userModules?.filter(m =>
                    m.parentId === action.application.id &&
                    m.showOnNavigation &&
                    !!state.client.userModules.find(x => x.id === m.id)
                ) ?? [];

            services.sort((m1, m2) => m1.displayOrder - m2.displayOrder);

            return fromConnect.SetServices({ services, performRedirect: action.performRedirect });
        })));

    setServices$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetServices),
        filter(action => action.performRedirect),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const service = state.services[0];
            return fromConnect.SetService({ service, performRedirect: action.performRedirect });
        })));

    selectService$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SelectService),
        withLatestFrom(this.store$.select(fromConnect.getApplicationState)),
        map(([action, state]) => {
            const service = state.services?.find(m => m.id === action.serviceId);
            return fromConnect.SetService({ service, performRedirect: action.performRedirect });
        })));

    setService$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetService),
        filter(action => action.performRedirect),
        map(action => fromConnect.NavigateToUrl({ url: action.service.url }))));

    navigateToUrl$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.NavigateToUrl),
        tap(action => {
            this.router.navigateByUrl(action.url);
        })), { dispatch: false });

    logoutUser$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.LogoutUser),
        map((action) => fromAuthV2.Logout({options: action.options})
    )));

    refreshToken$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.RefreshToken),
        map(() => {
            const request = this.authenticationTokenService.getRefreshTokenRequest();
            return fromAuthV2.RefreshToken({ request });
        }
    )));

    rehydrateUser$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.RehydrateUser),
        map(() => fromAuthV2.RehydrateUser()
    )));

    rehydrateUserSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.RehydrateUserSuccess),
        switchMap((action) => {
            ModuleService.populate(action.user);
            ModuleService.setClient(action.client);
            this.authenticationTokenService.setActiveClient(action.client.id);
            if (action.userGroup) {
                this.authenticationTokenService.setUserGroup(action.userGroup.id);
            }
            this.authenticationTokenService.setAuthToken(action.user.token);
            return [fromConnect.SetBranding({ branding: action.client.branding}),
                fromConnect.SetTokenSuccess({ performRedirect: false, setServiceFromUrl: true })];
        }
    )));

    setUserForTokenLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromConnect.SetUserForTokenLogin),
            switchMap((action) => {
                ModuleService.populate(action.user);
                ModuleService.setClient(action.client);

                this.authenticationTokenService.setActiveClient(
                    action.client.id
                );
                this.authenticationTokenService.setAuthToken(action.user.token);

                const userGroup = (() => {
                    if (action.client.userGroups?.length === 1) {
                        return action.client.userGroups[0];
                    }

                    if (
                        action.token.userGroupId &&
                        action.client.userGroups?.find(
                            (x) => x.id === action.token.userGroupId
                        )
                    ) {
                        return action.client.userGroups.find(
                            (x) => x.id === action.token.userGroupId
                        );
                    }

                    return null;
                })();

                if (userGroup) {
                    this.authenticationTokenService.setUserGroup(userGroup.id);
                }

                const actions: Action[] = [
                    fromConnect.SetBranding({ branding: action.client.branding }),
                    fromConnect.SetReadOnlyAccess({ readOnlyAccess: action.token.readOnlyAccess }),
                    action.token.hideHeader
                        ? fromConnect.HideHeader()
                        : fromConnect.ShowHeader(),
                    fromConnect.SetClientLogoVisibility({ visible: action.token.showClientLogo }),
                    fromConnect.SetNavigationMenuVisibility({ visible: action.token.showNavigationMenu }),
                    fromConnect.SetUserProfileButtonVisibility({ visible: action.token.showUserProfileButton }),
                    fromConnect.TokenRedirect({ token: action.token, modules: action.client.userModules }),
                ];

                return actions;
            })
        )
    );

    handleTokenRedirect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromConnect.TokenRedirect),
            map((action) => {
                const redirectUrl = (): string => {
                    if (action.token.processId && action.token.pdfExportId) {
                        return `/portal/processes/${action.token.processId}/pdf-export/${action.token.pdfExportId}`;
                    }

                    if (action.token.processId) {
                        return `/portal/processes/${action.token.processId}`;
                    }

                    switch (action.token.target) {
                        case MagicLinkTarget.EntityModule:
                            return '/portal/entities';
                        case MagicLinkTarget.EntityProfile:
                            return `/portal/entities/${action.token.entityId}/active-checks`;
                        case MagicLinkTarget.InviteWizard:
                            return '/portal/create-invite/launch';
                    }
                };

                const url = redirectUrl();
                const service = action.modules.find(
                    (m) => m.parentId && m.url && url.startsWith(m.url)
                );
                const application = action.modules.find(
                    (m) => m.id === service?.parentId
                );

                return { url, service, application, modules: action.modules };
            }),
            switchMap((action) => {
                const actions: Action[] = [
                    fromConnect.NavigateToUrl({
                        url: action.url,
                    }),
                    fromConnect.SetApplications({
                        modules: action.modules,
                        performRedirect: false,
                        setServiceFromUrl: false,
                    }),
                ];

                if (action.application) {
                    actions.push(
                        fromConnect.SelectApplication({
                            applicationId: action.application.id,
                            performRedirect: false,
                        })
                    );
                }

                if (action.service) {
                    actions.push(
                        fromConnect.SelectService({
                            serviceId: action.service.id,
                            performRedirect: false,
                        })
                    );
                }

                return actions;
            })
        )
    );

    hideHeader$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.HideHeader),
        tap(() => this.authenticationTokenService.setHideHeader(true))
        ), { dispatch: false });

    showHeader$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.ShowHeader),
        tap(() => this.authenticationTokenService.setHideHeader(false))
        ), { dispatch: false });

    setReadOnlyAcccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetReadOnlyAccess),
        tap((action) => this.authenticationTokenService.setReadOnlyAccess(action.readOnlyAccess))
        ), { dispatch: false });


    setClientLogoVisibility$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromConnect.SetClientLogoVisibility),
                tap(
                    (action) =>
                        (this.authenticationTokenService.showClientLogo =
                            action.visible)
                )
            ),
        { dispatch: false }
    );

    setNavigationMenuVisibility$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromConnect.SetNavigationMenuVisibility),
                tap(
                    (action) =>
                        (this.authenticationTokenService.showNavigationMenu =
                            action.visible)
                )
            ),
        { dispatch: false }
    );

    setUserProfileButtonVisibility$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromConnect.SetUserProfileButtonVisibility),
                tap(
                    (action) =>
                        (this.authenticationTokenService.showUserProfileButton =
                            action.visible)
                )
            ),
        { dispatch: false }
    );

    noPermissions$ = createEffect(() =>  this.actions$.pipe(
        ofType(fromConnect.NoPermissionsForClientUser),
        tap((action) => this.alertService.error(`You do not have any permissions for ${action.clientName}.`)
        )
    ), { dispatch: false });

    setUserGroup$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetUserGroup),
        map(action =>
             fromConnect.SetUserGroupToken({
                    clientId: action.clientId,
                    userGroup: action.userGroup,
                    performRedirect: action.performRedirect,
                    setServiceFromUrl: action.setServiceFromUrl
            })
        )
    ));

    setUserGroupToken$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetUserGroupToken),
        switchMap(action => this.authV2Service.setUserGroup(action.clientId, action.userGroup.id).pipe(
                map((token: TokenResponse) => {
                    this.authenticationTokenService.setActiveClient(action.clientId);
                    this.authenticationTokenService.setUserGroup(action.userGroup.id);
                    this.authenticationTokenService.setAuthToken(token);
                    return fromConnect.SetAndPopulateClient({ clientId: action.clientId, performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl});
                }),
                catchError(() => of(fromConnect.SetUserGroupFail()))
            ))
    ));

    clearUserGroup$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.ClearUserGroup),
        switchMap(action => this.authV2Service.clearUserGroup().pipe(
                map((token: TokenResponse) => {
                    this.authenticationTokenService.setUserGroup(null);
                    this.authenticationTokenService.setAuthToken(token);
                    return fromConnect.SetTokenSuccess({ performRedirect: action.performRedirect, setServiceFromUrl: action.setServiceFromUrl });
                }),
                catchError(() => of(fromConnect.ClearUserGroupFail()))
            ))
    ));

    setCharegableMode$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetChargeableMode),
        switchMap(action => this.authV2Service.setChargeableMode$(action.chargeableMode).pipe(
            map(() => fromConnect.SetChargeableModeSuccess()),
            catchError(() => of(fromConnect.SetChargeableModeFail()))
        ))
    ));
    setCharegableModeSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetChargeableModeSuccess),
            map(() => fromConnect.RehydrateUser())
        )
    );
    setCharegableModeFail$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetChargeableModeFail),
            tap(() => this.alertService.error(`Could not change the Chargeable Mode setting.`)
        )
    ), { dispatch: false });

    displayCharegableModeWarning$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.DisplayChargeableModeWarning),
        tap(() => {
            this.sessionStateService.setDisplayChargeableModeWarningTimestamp();
            this.dialogs.open(ChargeableModeWarningDialogComponent);
        })
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private store$: Store<fromConnect.ConnectStoreState>,
        private rootStore$: Store<fromRoot.State>,
        private authenticationTokenService: AuthenticationTokenService,
        private authV2Service: AuthenticationV2Service,
        private alertService: AlertService,
        private router: Router,
        private navigationService: NavigationService,
        private dialogs: MatLegacyDialog,
        private sessionStateService: SessionStateService) { }
}