// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { Client } from 'app/models/client.model';
import { User } from 'app/models/user.model';
import { LogoutOptions } from 'app/models/logout-options.model';
import { UserGroup } from 'app/models/user-group.model';
import { UserModule } from 'app/models/user-module.model';
import { TokenResponse } from 'app/shared/models';
import { TokenLoginDetails } from 'app/authentication-v2/models/token-login-details.model';

// enums
import { Modules } from 'app/shared/enums';

export const RehydrateUser = createAction(
    '[Connect Application] Rehydrate the application data'
);

export const RehydrateUserSuccess = createAction(
    '[Connect Application] Rehydrate the application data success',
    props<{user: User; client: Client; userGroup: UserGroup}>()
);

export const SetUser = createAction(
    '[Connect Application] Set the user',
    props<{user: User; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SelectClient = createAction(
    '[Connect Application] Select the client',
    props<{clientId: string; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetAndPopulateClient = createAction(
    '[Connect Application] Set and populate the client',
    props<{clientId: string; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetAndPopulateClientSuccess = createAction(
    '[Connect Application] Set and populate the client success',
    props<{client: Client; performRedirect: boolean; setServiceFromUrl: boolean; token: TokenResponse}>()
);

export const SetClientFail = createAction(
    '[Connect Application] Set the client fail'
);

export const SetTokenSuccess = createAction(
    '[Connect Application] Set the token success',
    props<{performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetApplications = createAction(
    '[Connect Application] Set applications',
    props<{modules: UserModule[]; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetApplicationFromUrl = createAction(
    '[Connect Application] Set application from URL'
);

export const SetServices = createAction(
    '[Connect Application] Set services',
    props<{services: UserModule[]; performRedirect: boolean}>()
);

export const SelectApplication = createAction(
    '[Connect Application] Select an application',
    props<{applicationId: Modules; performRedirect: boolean}>()
);

export const SetApplication = createAction(
    '[Connect Application] Set the selected application',
    props<{application: UserModule; performRedirect: boolean}>()
);

export const SelectService = createAction(
    '[Connect Application] Select the service',
    props<{serviceId: Modules; performRedirect: boolean}>()
);

export const SetService = createAction(
    '[Connect Application] Set the selected service success',
    props<{service: UserModule; performRedirect: boolean}>()
);

export const LogoutUser = createAction(
    '[Connect Application] Log out user',
    props<{options: LogoutOptions}>()
);

export const SetUserForTokenLogin = createAction(
    '[Connect Application] Set for token login',
    props<{user: User; client: Client; token: TokenLoginDetails}>()
);

export const NoPermissionsForClientUser = createAction(
    '[Connect Application] No permissions found for Client User',
    props<{ clientName: string}>()
);

export const SetUserGroup = createAction(
    '[Connect Application] Set the user group',
    props<{clientId: string; userGroup: UserGroup; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const SetUserGroupFail = createAction(
    '[Connect Application] Set the user group fail'
);

export const SetUserGroupToken = createAction(
    '[Connect Application] Set the user group token',
    props<{clientId: string; userGroup: UserGroup; performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const ClearUserGroup = createAction(
    '[Connect Application] Clear the user group',
    props<{performRedirect: boolean; setServiceFromUrl: boolean}>()
);

export const ClearUserGroupFail = createAction(
    '[Connect Application] Clear the user group fail'
);

export const RefreshToken = createAction(
    '[Connect Application] Refresh the token'
);

export const TokenRedirect = createAction(
    '[Connect Application] Token redirect',
    props<{ token: TokenLoginDetails; modules: UserModule[] }>()
);

export const SetChargeableMode = createAction(
    '[Connect Application] Set chargeable mode',
    props<{ chargeableMode: boolean }>()
);
export const SetChargeableModeSuccess = createAction(
    '[Connect Application] Set chargeable mode success'
);
export const SetChargeableModeFail = createAction(
    '[Connect Application] Set chargeable mode fail'
);

export const DisplayChargeableModeWarning = createAction(
    '[Connect Application] Display chargeable mode warning'
);
