import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
} from '@angular/core';

// models
import { EntitySummary, getEntityStatusTitle } from 'app/portal/modules/entity-list/models/entity-summary.model';

// enums
import { EntityStatus } from 'app/portal/modules/entity-list/enumerations/entity-status.enum';
import { EntityUserStatus } from 'app/portal/modules/entity-list/enumerations/entity-user-status.enum';

@Component({
    selector: 'app-entity-status',
    templateUrl: './entity-status.component.html',
    styleUrls: ['./entity-status.component.scss'],
})
export class EntityStatusComponent implements OnChanges {
    @Input()
    item: EntitySummary;

    @Input()
    label: string = undefined;

    @Input()
    showIcon: boolean;

    @Input()
    setTextColor: boolean = true;

    @Output()
    bouncedInviteClicked: EventEmitter<void> = new EventEmitter();

    statusLabel: string;
    statusClass: string;
    showBouncedEmailLink = false;

    ngOnChanges() {
        this.setStatusLabel();
    }

    setStatusLabel() {
        if (!this.item) {
            return;
        }

        this.statusClass = this.getEntityStatusClassName(this.item);
        this.statusLabel = getEntityStatusTitle(this.item.status, this.item.userStatus, this.item.inPerson);
    }

    getEntityStatusClassName(entity: EntitySummary): string {
        // When the entity status is expired we want to ignore the entity user status
        // and directly return expired
        if (entity.status === EntityStatus.Expired) {
            return 'expired';
        }

        if (entity.inPerson) {
            return 'new';
        } else {
            switch (entity.userStatus) {
                case EntityUserStatus.Invited:
                    return 'invited';
                case EntityUserStatus.Active:
                    return 'active';
                case EntityUserStatus.New:
                    return 'new';
                case EntityUserStatus.BouncedInvite:
                    return 'bounced';
            }
        }
    }

    handleBouncedInviteClicked(): void {
        this.bouncedInviteClicked.emit();
    }
}
