import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// ngrx | rxjs
import { Store, select, ActionsSubject } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

// store
import * as fromAuth from 'app/authentication-v2/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { CodeEntryComponent, ValueChangedEvent } from 'app/authentication-v2/components/code-entry/code-entry.component';

// services
import { NavigationService } from 'app/shared/services/navigation.service';

// models
import { RegExRules } from 'app/shared/models';

// enums
import { ContactType } from 'app/authentication-v2/enumerations/contact-type.enum';
import { OtpReason } from 'app/shared/enums/otp-reason.enum';

@Component({
    templateUrl: 'otp-page.component.html'
})
export class OtpPageComponent extends BaseComponent implements OnInit, AfterViewInit {
    RegExRules = new RegExRules();
    ContactType = ContactType;

    working: boolean;
    form: FormGroup;
    type?: ContactType;
    confirmationId: Guid;
    reason: OtpReason;

    @ViewChild(CodeEntryComponent) codeEntry: CodeEntryComponent;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private fb: FormBuilder,
        public navigation: NavigationService,
        private route: ActivatedRoute,
        private actionsSubject: ActionsSubject
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.codeEntry?.resetForm();
    }

    get source(): string {
        return this.type === ContactType.Phone ? 'a text' : this.type === ContactType.Email ? 'an email' : 'a message';
    }

    get device(): string {
        return this.type === ContactType.Phone ? 'your mobile' : this.type === ContactType.Email ? 'your email address' : 'you';
    }

    get pageTitle(): string {
        const source = this.type === ContactType.Phone ? 'texts' : this.type === ContactType.Email ? 'emails' : 'messages';

        return `Check your ${source}`;
    }

    ngOnInit(): void {
        this.codeEntry?.resetForm();

        this.route.queryParams
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(query => {
                this.type = query.type ? Number(query.type) : undefined;
                this.confirmationId = query.confirmationId;
                this.reason = query.reason ? Number(query.reason) : OtpReason.Unknown;
                this.codeEntry?.resetForm();
            });

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking))
            .subscribe((working: boolean) => this.working = working);

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.ConfirmOtpSuccess)
            )
            .subscribe((action) => {
                switch (this.reason) {
                    case OtpReason.SecurityResetEmailAddressConfirmation:
                        this.navigation.gotoOtp(null, ContactType.Phone, OtpReason.SecurityResetPhoneNumberConfirmation);
                        break;

                    case OtpReason.SecurityResetPhoneNumberConfirmation:
                        this.navigation.gotoWhatToReset();
                        break;

                    case OtpReason.MobileNumberConfirmation:
                        this.store.dispatch(fromAuth.LoginSuccess({ user: action.user }));
                        break;
                }
            });

        this.form = this.fb.group({
            otpCode: [null, [Validators.required, Validators.pattern(this.RegExRules.otp_code)]]
        });
    }

    onEnterPressed() {
        if (this.form.invalid) {
            return;
        }

        this.store.dispatch(fromAuth.ConfirmOtp({
            code: this.form.value.otpCode,
            confirmationId: this.confirmationId
        }));
    }

    onValueChanged(event: ValueChangedEvent): void {
        this.form.get('otpCode').setValue(event.code);
    }
}