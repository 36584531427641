import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// rxjs
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// utilities
import { rotateImage } from 'app/shared/utilities/image-utilities';

@Component({
    selector: 'app-image',
    templateUrl: 'secure-image.component.html'
})
export class SecureImageComponent extends BaseComponent implements OnChanges {

    @ViewChild('image')
    image: ElementRef;

    @Input()
    src: string;

    @Input()
    className: string;

    @Input()
    style: any;

    @Input()
    noImageIcon: string = 'fa-image';

    @Input()
    canZoom: boolean = false;

    @Input()
    noImageIconSizeInPx = 120;

    @Input()
    noImageIconClassList: string | string[] = ['padding-32', 'align-center', 'grey-200-text'];

    @Output()
    imageClick: EventEmitter<any> = new EventEmitter();

    private src$ = new BehaviorSubject(null);
    imageData: string;

    constructor(
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer
    ) {
        super();

        this.src$
            .pipe(
                // the filter is important to ensure the flow gets triggered
                tap(() => this.imageData = null),
                filter((x) => !!x),
                takeUntil(this.ngUnsubscribe),
                switchMap((url) => this.loadImage(url))
            )
            .subscribe((image) => (this.imageData = image));
    }

    ngOnChanges(): void {
        if (this.src !== this.src$.value) {
            this.src$.next(this.src);
        }
    }

    onClicked($event: any): void {
        this.imageClick.emit($event);
    }

    private loadImage(url: string): Observable<any> {
        return this.httpClient
            .get(url, { responseType: 'blob' })
            .pipe(
                filter((e) => !!e),
                map((e) =>
                    this.domSanitizer.bypassSecurityTrustUrl(
                        URL.createObjectURL(e)
                    )
                )
            );
    }

    rotate() {
        rotateImage(this.image.nativeElement);
    }
}