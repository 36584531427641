import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// ngrx | rxjs
import { Store, select, ActionsSubject } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

// store
import * as fromAuth from 'app/authentication-v2/store';
import * as fromConnect from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { ValueChangedEvent } from 'app/authentication-v2/components/code-entry/code-entry.component';

// services
import { NavigationService } from 'app/shared/services/navigation.service';

// models
import { User } from 'app/models/user.model';
import { RegExRules } from 'app/shared/models';

@Component({
    templateUrl: 'enter-pin.component.html'
})
export class EnterPinComponent extends BaseComponent implements OnInit {
    RegExRules = new RegExRules();

    working: boolean;
    authenticationMessage: string;
    hidePassword: boolean = true;
    form: FormGroup;
    loggedIn: boolean = false;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private fb: FormBuilder,
        public navigation: NavigationService,
        private actionsSubject: ActionsSubject) {
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking))
            .subscribe((working: boolean) => this.working = working);

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationMessage))
            .subscribe((authenticationMessage: string) => {
                this.authenticationMessage = authenticationMessage;
            });

        this.form = this.fb.group({
            pin: [null, [Validators.required, Validators.pattern(this.RegExRules.pin)]]
        });

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getUser))
            .subscribe((user: User) => {
                this.loggedIn = user !== null;
            });

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.ConfirmPinFail)
            )
            .subscribe((action) => this.authenticationMessage = action.message);
    }

    onEnterPressed() {
        if (this.form.invalid) {
            return;
        }

        this.store.dispatch(fromAuth.ConfirmPin({ pin: this.form.value.pin }));
    }

    onValueChanged(event: ValueChangedEvent): void {
        this.form.get('pin').setValue(event.code);
    }
}