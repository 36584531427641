import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// ngrx | rxjs
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromAuth from 'app/authentication-v2/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// services
import { AuthenticationEventTrackingService } from 'app/authentication-v2/services/authentication-event-tracking.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { NavigationService } from 'app/shared/services/navigation.service';

// utilities
import { getBoolean } from 'app/shared/utilities/boolean-utilities';

// models
import { TokenLoginDetails } from 'app/authentication-v2/models/token-login-details.model';

@Component({
    templateUrl: 'login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {

    working: boolean;
    authenticationMessage: string;
    hidePassword: boolean = true;
    form: FormGroup;
    isIframe: boolean;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private alertService: AlertService,
        public authenticationEventTrackingService: AuthenticationEventTrackingService,
        public navigation: NavigationService) {
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking))
            .subscribe((working: boolean) => this.working = working);

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationMessage))
            .subscribe((authenticationMessage: string) => {
                this.authenticationMessage = authenticationMessage;

                if (this.isIframe) {
                    this.alertService.error(this.authenticationMessage);
                }
            });

        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(255)]],
            password: [null, [Validators.required, Validators.minLength(8)]],
            rememberMe: [false]
        });

        this.isIframe = getBoolean(
            this.route.snapshot.queryParams.hideHeader,
            false
        );

        this.checkRequestParameters();
    }

    loginUser() {
        this.authenticationEventTrackingService.loginClicked();
        const request = this.form.value;
        this.store.dispatch(fromAuth.Login({request}));
    }

    checkRequestParameters() {
        const params = this.route.snapshot.queryParams;

        if (!params.token) {
            return;
        }

        const tokenLogin: TokenLoginDetails = {
            token: params.token,
            clientId: params.client ?? null,
            hideHeader: getBoolean(params.hideHeader, false),
            pdfExportId: params.pdfExportId ?? null,
            processId: params.process ?? null,
            readOnlyAccess: getBoolean(params.hideHeader, false),
            userGroupId: params.userGroup ?? null,
            entityId: params.entity ?? null,
            showClientLogo: getBoolean(params.showClientLogo, false),
            showNavigationMenu: getBoolean(params.showNavigationMenu, false),
            showUserProfileButton: getBoolean(params.showUserProfileButton, false),
            target: Number(params.target),
        };

        this.store.dispatch(fromAuth.TokenLogin({ request: tokenLogin }));
    }
}