import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Services
import { AuthenticationEventTrackingService } from './services/authentication-event-tracking.service';

// Components
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SetPinComponent } from './components/set-pin/set-pin.component';
import { MobileNumberComponent } from './components/mobile-number/mobile-number.component';
import { ForgotLoginDetailsComponent } from './components/forgot-login-details/forgot-login-details.component';
import { EnterPinComponent } from './components/enter-pin/enter-pin.component';
import { ContactAdministratorComponent } from './components/contact-administrator/contact-administrator.component';
import { OtpPageComponent } from 'app/authentication-v2/components/otp-page/otp-page.component';
import { ResetSecurityComponent } from 'app/authentication-v2/components/reset-security/reset-security.component';
import { WhatToResetComponent } from 'app/authentication-v2/components/what-to-reset/what-to-reset.component';

const routes: Routes = [
    { path: '', redirectTo: '/auth-v2/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'set-password', component: ResetPasswordComponent },
    { path: 'set-pin', component: SetPinComponent },
    { path: 'mobile-number', component: MobileNumberComponent },
    { path: 'otp-check', component: OtpPageComponent },
    { path: 'enter-pin', component: EnterPinComponent },
    { path: 'forgot-details', component: ForgotLoginDetailsComponent },
    { path: 'contact-administrator', component: ContactAdministratorComponent },
    { path: 'reset-security', component: ResetSecurityComponent },
    { path: 'what-to-reset', component: WhatToResetComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthenticationEventTrackingService]
})
export class AuthenticationV2RoutingModule { }
