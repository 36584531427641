import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormGroup, FormBuilder, Validators, AbstractControlOptions, ValidatorFn } from '@angular/forms';

// ngrx | rxjs
import { takeUntil } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';

// store
import * as fromAuth from 'app/authentication-v2/store';
import * as fromConnect from 'app/connect/store';

// services
import { ValidationService } from 'app/shared/services';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { NavigationService } from 'app/shared/services/navigation.service';

// components
import { OtpCheckDialogComponent } from 'app/authentication-v2/dialogs/otp-check-dialog/otp-check-dialog.component';
import { BaseComponent } from 'app/shared/base/base-component';
import { ValueChangedEvent } from 'app/authentication-v2/components/code-entry/code-entry.component';

// Models
import { OtpCheckDialogData } from 'app/authentication-v2/models/otp-check-dialog-data.model';
import { UserProfileUpdateResult } from 'app/authentication-v2/models/user-profile-update-result.model';
import { UserProfileUpdate } from 'app/authentication-v2/models/user-profile-update.model';
import { AuthenticatedUser, RegExRules } from 'app/shared/models';

// validators
import { PasswordValidation } from 'app/authentication-v2/models/password-validation';

// utils
import { isNullOrWhiteSpace } from 'app/shared/utilities/string-utilities';
import { getFormGroup } from 'app/shared/components/mobile-phone-entry/mobile-phone-entry.component';

// enums
import { ContactType } from 'app/authentication-v2/enumerations/contact-type.enum';

@Component({
    templateUrl: './user-profile-dialog.component.html'
})
export class UserProfileDialogComponent extends BaseComponent implements OnInit {

    userDetailsForm: FormGroup;
    passwordForm: FormGroup;
    pinForm: FormGroup;
    user: AuthenticatedUser;
    regexRules: RegExRules = new RegExRules();

    hideCurrent: boolean = true;
    hideNew: boolean = true;
    hideConfirm: boolean = true;

    constructor(public dialogRef: MatDialogRef<UserProfileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        public validationService: ValidationService,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private store: Store<fromAuth.AuthenticationState>,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private actionsSubject: ActionsSubject,
        public navigation: NavigationService,
        private dialogs: MatDialog
    ) {
        super();
        this.user = data.user;
    }

    ngOnInit(): void {

        this.userDetailsForm = this.formBuilder.group({
            id: [''],
            firstname: ['', [Validators.required, Validators.pattern(this.regexRules.name), Validators.minLength(2), Validators.maxLength(60)]],
            surname: ['', [Validators.required, Validators.pattern(this.regexRules.name), Validators.minLength(2), Validators.maxLength(60)]],
            mobile: getFormGroup(this.formBuilder, true)
        });

        this.passwordForm = this.formBuilder.group({
            currentPassword: [''],
            password: ['', [Validators.pattern(this.regexRules.password), Validators.minLength(9)]],
            confirmPassword: ['', [Validators.pattern(this.regexRules.password), Validators.minLength(9)]],
        }, <AbstractControlOptions>{
            validators: PasswordValidation.matchPassword
        });

        this.pinForm = this.formBuilder.group({
            oldPin: [null, [this.requiredIfChangingPin(), Validators.pattern(this.regexRules.pin)]],
            newPin: [null, [Validators.pattern(this.regexRules.pin)]]
        });

        this.loadData();

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(fromAuth.UpdateUserProfileSuccess))
            .subscribe((action) => {
                const r = action.response;
                if (r.errorUpdatingPassword || r.errorUpdatingUser || r.errorUpdatingPin) {
                    this.displayError(r);
                } else {
                    this.alertService.success('Your account details have been updated.');
                    this.dialogRef.close();
                    if (r.mobileNumberConfirmationId) {
                        this.openOtpDialog(r.mobileNumberConfirmationId);
                    }

                    this.connectStore.dispatch(fromConnect.RehydrateUser());
                }
            });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(fromAuth.UpdateUserProfileFail))
            .subscribe(() => {
                this.alertService.error('We were unable to update your account details.');
            });
    }

    mustEnterCurrentPin(): boolean {
        const form = this.pinForm?.value;

        return form && isNullOrWhiteSpace(form.oldPin) && !isNullOrWhiteSpace(form.newPin);
    }

    private requiredIfChangingPin(): ValidatorFn {
        return () => this.mustEnterCurrentPin() ? { required: true } : null;
    }

    saveDisabled(): boolean {
        return this.userDetailsForm.invalid ||
            this.passwordForm.invalid ||
            !this.passwordFieldsValid() ||
            this.pinForm.invalid;
    }

    save() {
        const userProfileUpdate = this.getData();
        this.store.dispatch(fromAuth.UpdateUserProfile({ request: userProfileUpdate}));
    }

    private displayError(result: UserProfileUpdateResult) {
        let errorMessage = '';
        if (result.errorUpdatingPassword) {
            errorMessage = `Unable to reset password: ${result.passwordUpdateMessage}`;
        }
        if (result.errorUpdatingUser) {
            errorMessage = `${errorMessage} ${result.userUpdateMessage}`;
        }
        if (result.errorUpdatingPin) {
            errorMessage = `${errorMessage} ${result.pinUpdateMessage}`;
        }
        this.alertService.error(errorMessage);
    }

    private loadData() {
        this.userDetailsForm.get('id').setValue(this.user.id);
        this.userDetailsForm.get('firstname').setValue(this.user.firstName);
        this.userDetailsForm.get('surname').setValue(this.user.surname);
        this.userDetailsForm.get('mobile').get('mobileNumber').setValue(this.user.mobileNumber);
        this.userDetailsForm.get('mobile').get('diallingCode').setValue(this.user.diallingCode);
    }

    private getData(): UserProfileUpdate {
        const userProfile = new UserProfileUpdate();
        userProfile.id = this.userDetailsForm.get('id').value;
        userProfile.firstName = this.userDetailsForm.get('firstname').value;
        userProfile.surname = this.userDetailsForm.get('surname').value;
        userProfile.detailsChanged = this.userDetailsForm.dirty;
        userProfile.currentPassword = this.passwordForm.get('currentPassword').value;
        userProfile.newPassword = this.passwordForm.get('password').value;
        userProfile.updatePassword = this.passwordForm.dirty;
        userProfile.oldPin = this.pinForm.get('oldPin').value;
        userProfile.newPin = this.pinForm.get('newPin').value;
        userProfile.mobileNumber = this.userDetailsForm.get('mobile').get('mobileNumber').value;
        userProfile.diallingCode = this.userDetailsForm.get('mobile').get('diallingCode').value;
        return userProfile;
    }

    private passwordFieldsValid(): boolean {
        return (this.passwordHasValue('currentPassword') && this.passwordHasValue('password') && this.passwordHasValue('confirmPassword')) ||
               (!this.passwordHasValue('currentPassword') && !this.passwordHasValue('password') && !this.passwordHasValue('confirmPassword'));
    }

    private passwordHasValue(fieldName: string): boolean {
        return this.passwordForm.get(fieldName).value !== '';
    }

    onCurrentPinValueChanged(event: ValueChangedEvent): void {
        this.pinForm.get('oldPin').setValue(event.code?.trim());
    }

    onNewPinValueChanged(event: ValueChangedEvent): void {
        this.pinForm.get('newPin').setValue(event.code?.trim());
    }

    private openOtpDialog(confirmationId: Guid): void {
        this.dialogs.open(OtpCheckDialogComponent, {
            disableClose: true,
            data: {
                confirmationId,
                type: ContactType.Phone
            } as OtpCheckDialogData
        });
    }
}
