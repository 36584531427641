// Store
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'app/authentication-v2/store/actions';

// Models
import { TemporaryPasswordReset } from 'app/authentication-v2/models/temporary-password-reset.model';

// Enums
import { ClientUserTokenType } from 'app/shared/enums/client-user-token-type.enum';


export interface AuthenticationState {
    working: boolean;
    authenticationMessage: string;
    passwordResetOnLogin: TemporaryPasswordReset;
    acceptingTerms: boolean;
    resetPin: boolean;
    resetPassword: boolean;
    clientUserTokenType: ClientUserTokenType;
}

const initialState: AuthenticationState = {
    working: null,
    authenticationMessage: null,
    passwordResetOnLogin: null,
    acceptingTerms: false,
    resetPin: false,
    resetPassword: false,
    clientUserTokenType: null,
};

const authReducer = createReducer(
    initialState,
    on(actions.Login, (state) => ({
        ...state,
        working: true,
        authenticationMessage: null,
    })),
    on(actions.LoginFail, (state, action) => ({
        ...state,
        working: false,
        authenticationMessage: action.message,
    })),
    on(actions.LoginSuccess, (state, action) => ({
        ...state,
        working: false,
        clientUserTokenType: action.user.token.clientUserTokenType,
    })),
    on(actions.RehydrateUserSuccess, (state, action) => ({
        ...state,
        working: false,
        clientUserTokenType: action.user.token.clientUserTokenType,
    })),
    on(actions.RefreshTokenSuccess, (state, action) => ({
        ...state,
        clientUserTokenType: action.token.clientUserTokenType,
    })),
    on(actions.TokenLoginSuccess , (state, action) => ({
        ...state,
        clientUserTokenType: action.user.token.clientUserTokenType,
    })),
    on(actions.Logout, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.PasswordResetRequired, (state, action) => ({
        ...state,
        passwordResetOnLogin: action.request,
        working: false,
    })),
    on(actions.ClearPasswordResetState, (state) => ({
        ...state,
        passwordResetOnLogin: null,
        working: false,
    })),
    on(actions.TermsAccepted, (state) => ({
        ...state,
        acceptingTerms: true,
    })),
    on(actions.TermsAcceptedSuccess, (state) => ({
        ...state,
        acceptingTerms: false,
    })),
    on(actions.TermsAcceptedFail, (state) => ({
        ...state,
        acceptingTerms: false,
    })),

    on(actions.SetPin, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.SetPinSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.SetPinFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.SetMobileNumber, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.SetMobileNumberSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.SetMobileNumberFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.ConfirmOtp, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.ConfirmOtpSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.ConfirmOtpFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.ActionRequired, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.ConfirmPin, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.ConfirmPinSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.ConfirmPinFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.TriggerSecurityReset, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.TriggerSecurityResetSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.TriggerSecurityResetFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.RequestPinReset, (state, action) => ({
        ...state,
        working: true,
        resetPin: true,
        resetPassword: action.resetPassword,
    })),
    on(actions.RequestPinResetSuccess, (state) => ({
        ...state,
        working: false,
        resetPin: false,
    })),
    on(actions.RequestPinResetFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.RequestPasswordReset, (state, action) => ({
        ...state,
        working: true,
        resetPassword: true,
        resetPin: action.resetPin,
    })),
    on(actions.RequestPasswordResetSuccess, (state) => ({
        ...state,
        working: false,
        resetPassword: false,
    })),
    on(actions.RequestPasswordResetFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.SetTemporaryPin, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.SetTemporaryPinSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.SetTemporaryPinFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.GenerateTemporaryPassword, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.GenerateTemporaryPasswordSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.GenerateTemporaryPasswordFail, (state) => ({
        ...state,
        working: false,
    }))
);

export function reducer(state: AuthenticationState, action: Action) {
    return authReducer(state, action);
}