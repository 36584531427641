import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum UserAlertCategory {
    BankAccountCheck = 0,
    BouncedInvitation = 1,
    EsignDocument = 2,
    ExpiredInvitation = 3,
    IdentityCheck = 4,
    OpenBankingCheck = 5,
    ProcessComplete = 6,
    ProofOfOwnershipCheck = 7,
    SanctionAndPeps = 8,
    StandardCheck = 9,
    Task = 10,
    ProofOfOwnershipCheckRetrieval = 11,
    EntityImportComplete = 12
}

export const UserAlertCategories: EnumValueTitle<UserAlertCategory>[] = [
    { title: 'Bank account check', value: UserAlertCategory.BankAccountCheck },
    { title: 'Bounced invitation', value: UserAlertCategory.BouncedInvitation },
    { title: 'eSign document complete', value: UserAlertCategory.EsignDocument },
    { title: 'Expired invitation', value: UserAlertCategory.ExpiredInvitation },
    { title: 'Identity check', value: UserAlertCategory.IdentityCheck },
    { title: 'Open banking check', value: UserAlertCategory.OpenBankingCheck },
    { title: 'Process complete', value: UserAlertCategory.ProcessComplete },
    { title: 'Proof of Ownership check', value: UserAlertCategory.ProofOfOwnershipCheck },
    { title: 'International sanction & PEPS', value: UserAlertCategory.SanctionAndPeps },
    { title: 'Address and Mortality', value: UserAlertCategory.StandardCheck },
    { title: 'Task', value: UserAlertCategory.Task },
    { title: 'Proof of Ownership check', value: UserAlertCategory.ProofOfOwnershipCheckRetrieval },
    { title: `Individuals import complete`, value: UserAlertCategory.EntityImportComplete },
];

export function getUserAlertCategoryTitle(category: UserAlertCategory) {
    return UserAlertCategories.find((x) => x.value === category)?.title ?? '';
}