import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher, DateAdapter } from '@angular/material/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LazyLoadImageModule } from 'ng-lazyload-image';

// ngrx/rxjs
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';

// ngx-translate
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// Security Guards
import { AuthGuard, ModuleGuard, ShortUrlGuard } from './shared/guards';

// feature modules
import { AppRoutingModule } from './app-routing.module';

// feature components
import { AppComponent } from './app.component';

// services
import { AuthenticationV2Service } from './authentication-v2/services/authentication-v2.service';
import { AuthenticationTokenService } from './shared/services/authentication-token.service';
import { GlobalErrorHandler } from './shared/services/global-error-handler.service';
import { LogRocketService } from 'app/shared/services/log-rocket.service';

// store
import * as fromStore from './store';
import { effects } from './store/effects';
import { RouterEffects } from './store/effects/router.effects';

// App Modules
// TODO CONNECT - Any area modules should not be being consumed like this - this will be rectified when all the modules are in the right place

import { AuthenticationV2Module } from './authentication-v2/authentication-v2.module';
import { SharedModule } from './shared/shared.module';
import { ConnectModule } from './connect/connect.module';

import { environment } from 'environments/environment';
import { GbDateAdapter } from 'app/shared/models/gb-date-adapter';
import { RequestInterceptor, DEFAULT_TIMEOUT } from './shared/interceptors/request-interceptor';
import { ContentComponent } from './shared/components/content/content.component';

// models
import { StoreNames } from './connect/models/store-names.model';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        // TODO CONNECT - When we rewrite/rework we need to make the stores properly immutable, then we can remove these overrides
        StoreModule.forRoot(fromStore.reducers, { runtimeChecks: { strictStateImmutability: false, strictActionSerializability: false, strictActionImmutability: false, strictStateSerializability: false }}),

        StoreModule.forFeature(StoreNames.shared, fromStore.reducers),

        EffectsModule.forRoot([
            ...effects,
            RouterEffects,
        ]),
        environment.production === 'true'
            ? []
            : StoreDevtoolsModule.instrument({
                maxAge: 50 //  Retains last 50 states (used by Redux DevTools Chrome extension)
            }),
        AppRoutingModule,
        SharedModule,
        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
        NgxExtendedPdfViewerModule,
        AuthenticationV2Module,
        LazyLoadImageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ConnectModule,
    ],
    declarations: [
        AppComponent,
        ContentComponent
    ],
    providers: [
        {
            provide: ErrorStateMatcher,
            useClass: ShowOnDirtyErrorStateMatcher
        },
        AuthGuard,
        AuthenticationV2Service,
        ModuleGuard,
        {
            provide: DateAdapter,
            useClass: GbDateAdapter
        },
        AuthenticationTokenService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        {
            provide: DEFAULT_TIMEOUT,
            useValue: 120000
        },
        Title,
        Meta,
        NgxImageCompressService,
        ShortUrlGuard,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        LogRocketService
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule { }

// required for AOT compilation (ngx-translate)
export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
