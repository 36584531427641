<div id="user-alert-group">
    <div class="wrapper">
        <button class="header-wrapper"
                [attr.data-auto]="dataAuto"
                [title]="(group.collapsed ? 'Expand' : 'Collapse') + ' group'"
                (click)="toggle.emit(group.category)">
            <h2 class="header-title">{{group.title}}</h2>
            <div class="header-actions-wrapper">
                <span *ngIf="unreadCount" class="alerts-count">{{unreadCount}}</span>
                <i class="fa-light"
                   [class.fa-chevron-down]="group.collapsed"
                   [class.fa-chevron-up]="!group.collapsed"></i>
            </div>
        </button>
        <div #body
             class="body-wrapper"
             [ngStyle]="{ height: group.collapsed ? 0 : (body.scrollHeight + 'px') }">
            <ng-container *ngFor="let alert of (group.alerts | orderBy: 'date':'desc')">
                <div class="alert-wrapper" [class.read]="alert.read">
                    <div class="alert-header-wrapper">
                        <span class="alert-date">{{alert.date | date:'dd/MM/yyyy @ HH:mm'}}</span>
                        <button class="dismiss-button" title="Dismiss alert" (click)="dismiss.emit(alert)">
                            <i class="fa fa-solid fa-times"></i>
                        </button>
                    </div>
                    <div class="alert-body-wrapper">
                        <span class="alert-body-text" [ngSwitch]="alert.category">
                            <ng-container *ngSwitchCase="UserAlertCategory.Task">
                                {{alert.processTaskTitle}}
                            </ng-container>
                            <ng-container *ngSwitchCase="UserAlertCategory.EntityImportComplete">
                                Your import is now complete. 
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{alert.entityName}} {{alert.entityRegistrationCode}}
                            </ng-container>
                        </span>
                    </div>
                    <div class="alert-footer-wrapper">
                        <div [ngSwitch]="alert.category">
                            <ng-container *ngSwitchCase="UserAlertCategory.Task">
                                <app-lozenge [showIcon]="true"
                                             [useLightColors]="true"
                                             [variant]="getUserAlertTaskStatusLozengeVariant(alert.taskStatus)"
                                             [text]="getUserAlertTaskStatusTitle(alert.taskStatus)" />
                            </ng-container>
                            <ng-container *ngSwitchCase="UserAlertCategory.EntityImportComplete">
                                <app-lozenge [showIcon]="true"
                                             [useLightColors]="true"
                                             [variant]="getUserAlertImportStatusLozengeVariant(alert.importStatus)"
                                             [text]="getUserAlertImportStatusTitle(alert.importStatus)" />
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <app-lozenge *ngIf="alert.status !== UserAlertStatus.None"
                                             [showIcon]="true"
                                             [useLightColors]="true"
                                             [variant]="getUserAlertStatusLozengeVariant(alert.status)"
                                             [text]="getUserAlertStatusLozengeText(alert.status)" />
                            </ng-container>
                        </div>
                        <button class="btn empty icon-r review-button"
                                (click)="read.emit(alert)">
                            {{buttonText}}
                            <i class="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>