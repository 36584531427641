export function rotateImage(image: HTMLImageElement) {
    if (!image) {
        return;
    }

    const height = image.offsetHeight;
    const width = image.offsetWidth;

    const ratioX = width / height;
    const ratioY = height / width;

    const currentRotation = parseInt(image.dataset.currentRotation, 10) || 0;
    const nextRotation = (currentRotation + 1) % 4;

    let transform = '';

    transform += `rotate(${nextRotation * 90}deg) `;

    const div = image.parentElement;
    const landscape = div.offsetHeight < div.offsetWidth;

    if (currentRotation % 2 === 0) {
        if (landscape) {
            // The container is landscape
            transform += 'scaleX(' + ratioY + ') scaleY(' + ratioY + ')';
        } else {
            transform += 'scaleX(' + ratioX + ') scaleY(' + ratioX + ')';
        }
    }

    image.style.transform = transform;
    image.dataset.currentRotation = nextRotation.toString();
}
