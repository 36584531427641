import { createAction, props } from '@ngrx/store';

// models
import { ReceiveNotificationEvent } from 'app/connect/models/receive-notification-event.model';
import { ReceiveUserAlertEvent } from 'app/connect/models/receive-user-alert-event.model';
import { ReceiveVerifierTaskTimeoutEvent } from 'app/connect/models/receive-verifier-task-timeout-event.model';

export const SetClientGroup = createAction(
    '[Messaging Hub] Set client group',
    props<{ clientId: string }>()
);

export const SetClientGroupSuccess = createAction(
    '[Messaging Hub] Set client group success',
    props<{ clientId: string }>()
);

export const ReceiveNotification = createAction(
    '[Messaging Hub] Receive notification',
    props<ReceiveNotificationEvent>()
);

export const ReceiveAlert = createAction(
    '[Messaging Hub] Receive alert',
    props<ReceiveUserAlertEvent>()
);

export const ReceiveVerifierTaskTimeout = createAction(
    '[Messaging Hub] Receive verifier task timeout',
    props<ReceiveVerifierTaskTimeoutEvent>()
);
