export enum Modules {
    // Next module id to be 297 (please keep this up to date)
    /* CONNECT PORTAL */
    /* - APP */ ConnectPortal = 70, /* Connect Portal */
    /*   - MODULE */ ConnectPortalProcesses = 150, /* Allows the user to see the list of Processes for the Client */
    /*     - PERMISSION */ ConnectPortalProcessExport = 171, /* Allows the user to export Processes */
    /*     - PERMISSION */ ConnectPortalProcessCreate = 185, /* Allows the user to create Processes */
    /*     - PERMISSION */ ConnectPortalProcessCreateFolder = 186, /* Allows the user to create folders for Processes */
    /*     - PERMISSION */ ConnectPortalProcessAddToFolder = 187, /* Allows the user to add Processes to folders */
    /*     - PERMISSION */ ConnectPortalProcessAutoRefresh = 188, /* Allows the user to turn on auto-refresh on the Processes list */
    /*     - PERMISSION */ ConnectPortalProcessExportToCsv = 189, /* Allows the user to export Processes to CSV */
    /*     - PERMISSION */ ConnectPortalProcessDelete = 190, /* Allows the user to delete Processes */
    /*     - PERMISSION */ ConnectPortalProcessLock = 191, /* Allows the user to lock Processes */
    /*     - PERMISSION */ ConnectPortalProcessLockQuestion = 192, /* Allows the user to lock questions on Processes */
    /*     - PERMISSION */ ConnectPortalProcessViewStatusPanel = 226, /* Allows the user to view the status panel on Processes */
    /*     - PERMISSION */ ConnectPortalProcessViewActorPanel = 227, /* Allows the user to view the actor panel on Processes */
    /*     - PERMISSION */ ConnectPortalProcessViewValidationPanel = 228, /* Allows the user to view the validation summary on Processes */
    /*     - PERMISSION */ ConnectPortalProcessDownloadFilePack = 289, /* Allows the user to download the process file pack */
    /*   - MODULE */ ConnectPortalEntities = 151, /* Allows the user to see the list of Entities for the Client */
    /*     - PERMISSION */ ConnectPortalEntitiesAdmin = 159, /* Allows the user to create Entities */
    /*     - PERMISSION */ ConnectPortalEntitiesEvidence = 181, /* Allows the user to view and add entity evidence */
    /*     - PERMISSION */ ConnectPortalEntitiesInvite = 193, /* Allows the user to invite entities to a process */
    /*     - PERMISSION */ ConnectPortalEntitiesCreateFolder = 194, /* Allows the user to create folders for entities */
    /*     - PERMISSION */ ConnectPortalEntitiesAddToFolder = 195, /* Allows the user to add entities to folders */
    /*     - PERMISSION */ ConnectPortalEntitiesAutoRefresh = 196, /* Allows the   to turn on auto-refresh on the Entities list */
    /*     - PERMISSION */ ConnectPortalEntitiesExportToCsv = 197, /* Allows the user to export entities to CSV*/
    /*     - PERMISSION */ ConnectPortalEntitiesEditUserDetails = 198, /* Allows the user to edit entity user's details */
    /*     - PERMISSION */ ConnectPortalEntitiesDelete = 199, /* Allows the user to delete entities */
    /*     - PERMISSION */ ConnectPortalEntitiesAddDataCheck = 200, /* Allows the user to add data checks to entities */
    /*     - PERMISSION */ ConnectPortalEntitiesAddNote = 201, /* Allows the user to add notes to entities */
    /*     - PERMISSION */ ConnectPortalEntitiesCreateTag = 202, /* Allows the user to create tags for entities */
    /*     - PERMISSION */ ConnectPortalEntitiesAddTag = 203, /* Allows the user to add tags to entities */
    /*     - PERMISSION */ ConnectPortalEntitiesMilestones = 213, /* Allows the user to view entity milestones */
    /*     - PERMISSION */ ConnectPortalEntitiesProcesses = 220, /* Allows the user to view entity processes */
    /*     - PERMISSION */ ConnectPortalEntitiesIdentityChecks = 221, /* Allows the user to view entity identity checks */
    /*     - PERMISSION */ ConnectPortalEntitiesDocuments = 222, /* Allows the user to view entity documents */
    /*     - PERMISSION */ ConnectPortalEntitiesNotes = 223, /* Allows the user to view entity notes */
    /*     - PERMISSION */ ConnectPortalEntitiesPdfExports = 224, /* Allows the user to view entity PDF exports */
    /*     - PERMISSION */ ConnectPortalEntitiesExportEntityToPdf = 234, /* Allows the user to export entity to PDF*/
    /*     - PERMISSION */ ConnectPortalEntitiesBulkImport = 276, /* Allows the user to bulk import entities */
    /*     - PERMISSION */ ConnectPortalEntitiesDownloadFilePack = 290, /* Allows the user to download the entity file pack */
    /*   - MODULE */ ConnectPortalInvestigateCase = 166, /* Allows the user to perform "investigate case" */
    /*   - MODULE */ ConnectPortalDocuments = 174, /* Allows the user to view the document library */
    /*     - PERMISSION */ ConnectPortalDocumentsDownload = 216, /* Allows the user to download documents from the library */
    /*   - MODULE */ ConnectPortalSettings = 231, /* Allows users to manage portal settings */
    /*   - MODULE */ ConnectPortalUpdateStatusChecks = 249, /* Allows the user to update the status of a check */
    /*     - PERMISSION */ ConnectPortalUpdateStatusLivenessCheck = 250, /* Allows the user to update the status of a Liveness check */
    /*     - PERMISSION */ ConnectPortalUpdateStatusDocumentCheck = 251, /* Allows the user to update the status of an ID Document check */
    /*     - PERMISSION */ ConnectPortalUpdateStandardCheck = 252, /* Allows the user to update the status of a Standard check */
    /*     - PERMISSION */ ConnectPortalUpdateSanctionPepsCheck = 253, /* Allows the user to update the status of a Sanctions and PEPs check */
    /*     - PERMISSION */ ConnectPortalUpdateCappCheck = 257, /* Allows the user to update the status of a CAPP check */
    /*   - MODULE */ ConnectPortalDashboard = 267, /* Allows the user to view the Dashboard */
    /*   - MODULE */ ConnectPortalFolders = 295, /* Allows the user to create and manage folders */
    /*   - MODULE */ ConnectPortalCompanies = 296, /* Allows the user to create and manage companies */

    /* CONNECT MANAGEMENT */
    /* - APP */ ConnectManagement = 111, /* The user can view the connect management app */
    /*   - MODULE */ ConnectManagementSuperUsers = 119, /* Manage connect super users */
    /*   - MODULE */ ConnectPortalUsers = 154, /* Manage users within a client */
    /*     - PERMISSION */ ConnectPortalUsersCreate = 204, /* Allows the user to create users */
    /*     - PERMISSION */ ConnectPortalUsersEdit = 212, /* Allows the user to edit users */
    /*     - PERMISSION */ ConnectPortalUsersCopyResetLink = 205, /* Allows the user to copy reset password links */
    /*     - PERMISSION */ ConnectPortalUsersSendResetEmail = 206, /* Allows the user to send reset password emails */
    /*     - PERMISSION */ ConnectPortalUsersSetTemporaryPassword = 207, /* Allows the user to set temporary passwords */
    /*     - PERMISSION */ ConnectPortalUsersReissueApiKey = 208, /* Allows the user to re-issue API keys */
    /*     - PERMISSION */ ConnectPortalUsersRevokeApiKey = 209, /* Allows the user to revoke API keys */
    /*     - PERMISSION */ ConnectPortalUsersCopyApiKey = 210, /* Allows the user to copy API keys */
    /*     - PERMISSION */ ConnectPortalUsersRemove = 211, /* Allows the user to remove users */
    /*   - MODULE */ ConnectPortalUserGroups = 167, /* Manage user groups within a client */
    /*     - PERMISSION */ ConnectPortalUserGroupsCreate = 217, /* Allows the user to create user groups */
    /*     - PERMISSION */ ConnectPortalUserGroupsRemove = 215, /* Allows the user to remove user groups */
    /*   - MODULE */ ClientManagementClients = 170, /* User can administer reseller clients */
    /*     - PERMISSION */ ClientManagementClientsAddResellerChild = 218, /* User can add reseller clients */
    /*     - PERMISSION */ ClientManagementClientsEditResellerChild = 219, /* User can edit reseller clients */
    /*     - PERMISSION */ ClientManagementClientsEditDataCheckCredentials = 270, /* User can edit reseller client's data check settings (top category) */
    /*          - PERMISSION */ ClientManagementClientsEditDataCheckCredentialsOpenBanking = 271, /* User can edit reseller client's open banking settings */
    /*          - PERMISSION */ ClientManagementClientsEditDataCheckCredentialsID3Global = 272, /* User can edit reseller client's ID3 Global settings */
    /*          - PERMISSION */ ClientManagementClientsEditDataCheckCredentialsIDscan = 273, /* User can edit reseller client's IDscan settings */
    /*          - PERMISSION */ ClientManagementClientsEditDataCheckCredentialsAcuris = 274, /* User can edit reseller client's Acuris settings */
    /*     - PERMISSION */ ClientManagementClientsEditID3ProfileMappings = 275, /* User can edit reseller client's ID3 profile mappings */
    /*   - MODULE */ ConnectManagementSystemRoles = 247, /* User can administer super user roles */
    /*   - MODULE */ ConnectManagementClientRoles = 248, /* User can administer client roles */

    /* CUSTOMISATION CENTRE */
    /* - APP */ CustomisationCentre = 114, /* Allows the user to view the customisation centre */
    /*   - MODULE */ CustomisationCentreForms = 152, /* Allows the user to administer forms */
    /*     - PERMISSION */ CustomisationCentreFormsDataTransfer = 286, /* Allows the user to perform data transfer for forms */
    /*   - MODULE */ CustomisationCentreJourneys = 153, /* Allows the user to administer processes */
    /*     - PERMISSION */ CustomisationCentreJourneysOpenBankingSteps = 239, /* Allows the user to add open banking steps to journeys */
    /*     - PERMISSION */ CustomisationCentreJourneysPdfPacks = 269, /* Allows the user to add pdf packs to journeys */
    /*     - PERMISSION */ CustomisationCentreJourneysDataTransfer = 280, /* Allows the user to perform data transfer for journeys */
    /*   - MODULE */ CustomisationCentreInformationPages = 149, /* Allows the user to administer information pages */
    /*     - PERMISSION */ CustomisationCentreInformationPagesDataTransfer = 278, /* Allows the user to perform data transfer for information pages */
    /*   - MODULE */ CustomisationCentreTemplates = 158, /* Allows the user to view the Client template library */
    /*     - PERMISSION */ CustomisationCentreTemplatesDataTransfer = 279, /* Allows the user to perform data transfer for esign templates */
    /*   - MODULE */ CustomisationCentreLists = 165, /* Allows the user to edit lists */
    /*     - PERMISSION */ CustomisationCentreListsDataTransfer = 285, /* Allows the user to perform data transfer for lookup lists */
    /*   - MODULE */ CustomisationCentreRedirectUrls = 232, /* Allows users to add a redirect url as a journey step */

    /* BRANDING */
    /* - APP */ Branding = 172, /* Allows the user to view the branding application */
    /*   - MODULE */ BrandingEntityBranding = 161, /* Allows the user to edit the entity portal branding */
    /*   - MODULE */ BrandingClientBranding = 162, /* Allows the user to edit the client portal branding */
    /*   - MODULE */ BrandingClientAdverts = 163, /* Allows the user to edit the client adverts */
    /*   - MODULE */ CustomisationCentreSmsTemplates = 173, /* Allows the user to edit lists */
    /*     - PERMISSION */ CustomisationCentreSmsTemplatesDataTransfer = 282, /* Allows the user to data transfer SMS templates */
    /*   - MODULE */ CustomisationCenterEmailTemplate = 175, /* Allows the user to edit customisable email templates */
    /*     - PERMISSION */ CustomisationCenterEmailTemplateDataTransfer = 281, /* Allows the user to data transfer customisable email templates */

    /* INFO */
    /* - APP */ ConnectInfo = 177, /* Allows the user to view all kinds of info content */
    /*   - MODULE */ ConnectInfoFeatureRequests = 178, /* Allows the client to view feature requests */
    /*   - MODULE */ ConnectInfoReleaseNotes = 182, /* Allows the client to view active release notes */
    /*   - MODULE */ ConnectInfoRoadmaps = 184, /* Allows the client to view active roadmaps */
    /*   - MODULE */ ConnectInfoGlossary = 235, /* Allows the client to view active glossary */
    /*   - MODULE */ ConnectInfoGuides = 237, /* Allows the client to view active guides */

    /* PRODUCT CONFIGURATION */
    /* - APP */ ProductConfiguration = 179, /* Allows the user to make system wide configuration */
    /*   - MODULE */ ProductConfigurationSystemAdverts = 164, /* Allows the user to edit the system adverts */
    /*   - MODULE */ ProductConfigurationEmailTemplateTypes = 176, /* Allows the user to edit email template types */
    /*     - PERMISSION */ ProductConfigurationEmailTemplateTypesDataTransfer = 283, /* Allows the user to data transfer customisable email template types */
    /*   - MODULE */ ProductConfigurationReleaseNotes = 180, /* Allows super users to publish release notes via Connect rather than via email */
    /*   - MODULE */ ProductConfigurationRoadmaps = 183, /* Allows super users to publish roadmaps */
    /*   - MODULE */ ProductConfigurationGlossary = 236, /* Allows super users to publish glossary */
    /*   - MODULE */ ProductConfigurationGuides = 238, /* Allows super users to publish guides */
    /*   - MODULE */ ProductConfigurationDefaultEmails = 242, /* Allows super users to set up default emails */
    /*   - MODULE */ ProductConfigurationDefaultSmsTemplates = 284, /* Allows super users to set up default sms templates */

    /* SYSTEM CONFIGURATION */
    /* - APP */ SystemConfiguration = 243, /* Allows the user to make system wide configuration */
    /*   - MODULE */ ProductConfigurationTooltips = 214, /* Allows super users to publish tooltips, to help users navigate the system */
    /*   - MODULE */ ConnectManagementTerms = 157, /* Manage connect terms */
    /*   - MODULE */ ProductConfigurationHelpFaqs = 168, /* Allows the user to search for help and faq items */
    /*   - MODULE */ ProductConfigurationTelephoneNumbers = 240, /* Allows super users to publish telephone numbers */
    /*   - MODULE */ ProductConfigurationNonBiometricDocumentTypes = 241, /* Allows super users to set up non-biometric document types */
    /*   - MODULE */ ConnectManagementApplications = 160, /* Manage connect applications, services and permissions */
    /*   - MODULE */ ProductConfigurationPackages = 230, /* Allows super users to administer packages */
    /*   - MODULE */ ProductConfigurationNotifications = 246, /* Allows super users to publish notification */
    /*   - MODULE */ ProductConfigurationID3ProfileMappings = 266, /* Allows super users to assign active check type to ID3 profiles */

    /* LEGISLATIONS */
    /* - APP */ Legislation = 244, /* Allows users to administer legislations */
    /*   - MODULE */ LegislationDigitalIdentityTrustFramework = 245, /* Allows users to activate DITF profiles */
    /*   - MODULE */ LegislationRightToWork = 254, /* Allows users to activate RTW profiles */
    /*   - MODULE */ LegislationRightToRent = 255, /* Allows users to activate RTR profiles */
    /*   - MODULE */ LegislationSafeHarbour = 256, /* Allows users to activate Safe Harbour profiles */
    /*   - MODULE */ LegislationProofOfOwnership = 268, /* Allows users to activate Proof of Ownership profiles */

    /* CREDAS INTERNAL */
    /* - APP */ CredasInternal = 258, /* Credas Internal */
    /*   - MODULE */ CredasInternalAccessAllPortals = 259, /* Allows users to access all client portals */

    /* BACK OFFICE */
    /* - APP */ BackOffice = 260, /* Back office features */
    /*   - MODULE */ BackOfficeVerifier = 261, /* Back office verifier */
    /*     - PERMISSION */ BackOfficeVerifierIdDocument = 262, /* ID document verifier */
    /*     - PERMISSION */ BackOfficeVerifierLiveness = 263, /* Liveness verifier */
    /*     - PERMISSION */ BackOfficeVerifierShareCode = 264, /* Share code verifier */
    /*     - PERMISSION */ BackOfficeVerifierCifas = 265, /* Share code verifier */
    /*     - PERMISSION */ BackOfficeVerifierAddressMortality = 287, /* Address and mortality verifier */
    /*     - PERMISSION */ BackOfficeVerifierSanctionPep = 288, /* Sanction adn pep verifier */
    /*     - PERMISSION */ BackOfficeVerifierRegistersOfScotland = 291, /* Scottish LR verifier */
    /*     - PERMISSION */ BackOfficeVerifierNILandRegistry = 292, /* N. Ireland LR verifier */
    /*   - MODULE */ ConnectManagementEndUsers = 225, /* Manage connect end users */
    /*   - MODULE */ ConnectManagementClients = 156, /* Manage connect clients */
    /*   - MODULE */ BackOfficeClientUsers = 277, /* Manage all client users */
    /* SERVICES */
    /* - APP */ Services = 293, /* Service management */
    /*   - MODULE */ ServicesCredasPlus = 294, /* Allows users to access credas plus service */
}

export function getDataAutoModuleName(module: Modules): string {
    return Modules[module]?.toLowerCase();
}

export function isLegislationModule(module: Modules): boolean {
    return legislationModules.includes(module);
}

export function isBackOfficeModule(module: Modules): boolean {
    return backOfficeModules.includes(module);
}

export function isUpdateCheckStatusModule(module: Modules): boolean {
    return updateCheckStatusModules.includes(module);
}

const legislationModules = [
    Modules.Legislation,
    Modules.LegislationDigitalIdentityTrustFramework,
    Modules.LegislationRightToWork,
    Modules.LegislationRightToRent,
    Modules.LegislationSafeHarbour,
    Modules.LegislationProofOfOwnership
];

const backOfficeModules = [
    Modules.BackOffice,
    Modules.BackOfficeVerifier,
    Modules.BackOfficeVerifierIdDocument,
    Modules.BackOfficeVerifierLiveness,
    Modules.BackOfficeVerifierShareCode,
    Modules.BackOfficeVerifierCifas,
    Modules.BackOfficeVerifierAddressMortality,
    Modules.BackOfficeVerifierSanctionPep,
];

const updateCheckStatusModules = [
    Modules.ConnectPortalUpdateStatusChecks,
    Modules.ConnectPortalUpdateStatusLivenessCheck,
    Modules.ConnectPortalUpdateStatusDocumentCheck,
    Modules.ConnectPortalUpdateStandardCheck,
    Modules.ConnectPortalUpdateSanctionPepsCheck,
    Modules.ConnectPortalUpdateCappCheck
];

export const DataCheckCredentialsModules = [
    Modules.ClientManagementClientsEditDataCheckCredentialsAcuris,
    Modules.ClientManagementClientsEditDataCheckCredentialsID3Global,
    Modules.ClientManagementClientsEditDataCheckCredentialsIDscan,
    Modules.ClientManagementClientsEditDataCheckCredentialsOpenBanking,
];

