import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';

// ngrx | rxjs
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

// store
import * as fromConnect from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { UserProfileDialogComponent } from 'app/authentication-v2/dialogs/user-profile-dialog/user-profile-dialog.component';

// models
import { Client } from 'app/models/client.model';
import { User } from 'app/models/user.model';
import { LogoutOptions } from 'app/models/logout-options.model';

// enums
import { TooltipPage } from 'app/connect/enums/tooltip-page.enum';
import { TermsDialogComponent } from 'app/authentication-v2/components/terms-dialog/terms-dialog.component';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent extends BaseComponent implements OnInit, AfterViewInit {
    logoUrl$: Observable<string>;
    client$: Observable<Client>;
    supportUrl$: Observable<string>;
    showUserMenu: boolean;
    showHelpFaqsMenu$: Observable<boolean>;
    expanded: boolean = false;

    get isCredasUser(): boolean {
        return (this.user?.isSuperUser ?? false) &&
            (this.user?.isCredasUser ?? false);
    }

    private user: User;

    private preventMenuClose = false;

    constructor(
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private dialogs: MatDialog) {
            super();
    }

    ngOnInit(): void {
        this.logoUrl$ = this.connectStore.select(fromConnect.getLogoUrl);
        this.client$ = this.connectStore.select(fromConnect.getClient);
        this.showHelpFaqsMenu$ = this.connectStore.select(fromConnect.getShowHelpFaqsOnNavigation);

        this.supportUrl$ = this.connectStore.select(fromConnect.getClient).pipe(
            takeUntil(this.ngUnsubscribe),
            filter(client => !!client),
            map(client => client.branding?.supportUrl)
        );

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getUser))
            .subscribe((user) => this.user = user);

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getShowUserMenu))
            .subscribe((showUserMenu) => {
                this.showUserMenu = showUserMenu;
                if (showUserMenu) {
                    this.connectStore.dispatch(fromConnect.GetTooltips({ page: TooltipPage.ClientPortalMyAccount }));
                }
            });
    }

    ngAfterViewInit(): void {
        this.expanded = true;
    }

    onLogoutClick(): void {
        this.connectStore.dispatch(fromConnect.LogoutUser({options: new LogoutOptions(true, true, null)}));
    }

    onSwitchAccountClick(): void {
        this.connectStore.dispatch(fromConnect.OpenClientSelector());
    }

    onSwitchUserGroupClick(): void {
        this.connectStore.dispatch(fromConnect.OpenUserGroupSelector());
    }

    onClearUserGroupClick(): void {
        this.connectStore.dispatch(fromConnect.ClearUserGroup({performRedirect: true, setServiceFromUrl: false}));
    }

    onEditProfileClick(): void {
        this.dialogs.open(UserProfileDialogComponent, {data: {user: this.user}});
    }

    onMenuClick(): void {
        if (this.preventMenuClose) {
            this.preventMenuClose = false;
            return;
        }

        this.connectStore.dispatch(fromConnect.CloseUserMenu());
    }

    onShowTermsClick(): void {
        this.dialogs.open(TermsDialogComponent, { disableClose: true });
    }

    onOpenHelpFaqSearchClick(): void {
        this.connectStore.dispatch(fromConnect.OpenHelpFaqSearch());
    }

    onPreventMenuClose() {
        this.preventMenuClose = true;
    }

    onChargeableModeChange(event: MatLegacySlideToggleChange): void {
        this.connectStore.dispatch(fromConnect.SetChargeableMode({
            chargeableMode: event.checked
        }));
    }
}