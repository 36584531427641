<app-dialog id="user-profile-dialog" dialogTitle="My Profile" defaultWidth="600px" defaultHeight="600px">
    <div body id="user-profile-container" fxLayout="column">
        <form name="userDetailsForm" [formGroup]="userDetailsForm" novalidate>
            <div>
                <div class="font-question-label">First Name</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="firstname">
                    <mat-error *ngIf="validationService.hasError(userDetailsForm.controls['firstname'])">
                        {{ validationService.getError(userDetailsForm.controls['firstname']) }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div>
                <div class="font-question-label">Surname</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="surname">
                    <mat-error *ngIf="validationService.hasError(userDetailsForm.controls['surname'])">
                        {{ validationService.getError(userDetailsForm.controls['surname']) }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <app-mobile-phone-entry [formGroup]="userDetailsForm" formGroupName="mobile" [isRequired]="true"></app-mobile-phone-entry>
            </div>
        </form>

        <form name="passwordForm" [formGroup]="passwordForm" novalidate>
            <div>
                <div class="font-question-label">Current Password (required for password change)</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="currentPassword" [type]="hideCurrent ? 'password' : 'text'" autocomplete="off">
                    <mat-icon matSuffix (click)="hideCurrent = !hideCurrent">{{hideCurrent ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </div>

            <div>
                <div class="font-question-label">New Password</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="password" [type]="hideNew ? 'password' : 'text'" autocomplete="off">
                    <mat-icon matSuffix (click)="hideNew = !hideNew">{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="validationService.hasError(passwordForm.controls['password'])">
                        {{ validationService.getError(passwordForm.controls['password']) }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div>
                <div class="font-question-label">Confirm New Password</div>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="confirmPassword" [type]="hideConfirm ? 'password' : 'text'" autocomplete="off">
                    <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="validationService.hasError(passwordForm.controls['confirmPassword'])">
                        {{ validationService.getError(passwordForm.controls['confirmPassword']) }}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>

        <form name="pinForm" [formGroup]="pinForm" novalidate>
            <div>
                <div class="font-question-label">Current PIN (required for PIN change)</div>
                <app-authentication-code-entry #codeEntry length="4"
                                               spacerInterval="2"
                                               (valueChanged)="onCurrentPinValueChanged($event)"
                                               [numericOnly]="true"
                                               [asPassword]="false"
                                               style="max-width: 250px; display: block;"></app-authentication-code-entry>
            </div>
            <div>
                <mat-error *ngIf="mustEnterCurrentPin()">
                    In order to set new PIN you need to provide current PIN.
                </mat-error>
            </div>

            <div>
                <div class="font-question-label">New PIN</div>
                <app-authentication-code-entry #codeEntry length="4"
                                               spacerInterval="2"
                                               (valueChanged)="onNewPinValueChanged($event)"
                                               [numericOnly]="true"
                                               [asPassword]="false"
                                               style="max-width: 250px; display: block;"></app-authentication-code-entry>
            </div>
        </form>
    </div>

    <div footer-actions>
        <button id="close-button" class="btn large primary light" mat-dialog-close cdkFocusInitial>Cancel</button>
        <button id="save-button" class="btn large primary dark" [disabled]="saveDisabled()" (click)="save()">Save</button>
    </div>
</app-dialog>
