import {
    Component,
    Input,
    OnInit,
    ViewContainerRef,
} from '@angular/core';

// components
import { TileGroupComponent } from 'app/shared/components/tile-group/tile-group.component';

@Component({
    selector: 'app-tile',
    templateUrl: 'tile.component.html',
    styleUrls: ['tile.component.scss'],
})
export class TileComponent implements OnInit {
    @Input()
    label: string;

    @Input()
    icon: string;

    @Input()
    value: any;

    @Input()
    checked: boolean;

    tileGroupComponent: TileGroupComponent;
    id: string;
    name: string;

    constructor(private viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        this.tileGroupComponent = this.viewContainerRef.injector.get(TileGroupComponent);
        this.id = crypto.randomUUID();
    }

    setName(name: string) {
        this.name = name;
    }

    onTileChange() {
        this.tileGroupComponent.syncValue(this.value);
    }
}
