import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum EntityUserStatus {
    New = 1,
    Invited = 2,
    Active = 4,
    Expired = 8,
    BouncedInvite = 9,
}

export const EntityUserStatuses: EnumValueTitle<EntityUserStatus>[] = [
    new EnumValueTitle(EntityUserStatus.New, 'No contact'),
    new EnumValueTitle(EntityUserStatus.Invited, 'Invited'),
    new EnumValueTitle(EntityUserStatus.Active, 'Active'),
    new EnumValueTitle(EntityUserStatus.Expired, 'Expired'),
    new EnumValueTitle(EntityUserStatus.BouncedInvite, 'Bounced Invite'),
];

export function getEntityUserStatusTitle(status: EntityUserStatus): string {
    return EntityUserStatuses.find((s) => s.value === status)?.title;
}

