export enum FileExtensions {
    Any = '*',
    Csv = 'csv',
    Jpg = 'jpg',
    Jpeg = 'jpeg',
    Gif = 'gif',
    Png = 'png',
    Doc = 'doc',
    Docx = 'docx',
    Xlsx = 'xlsx',
    Svg = 'svg',
    Pdf = 'pdf',
    Json = 'json',
    Jfif = 'jfif',
    Jfi = 'jfi',
    Html = 'html',
    Zip = 'zip'
}

export const jpgExtensions = [FileExtensions.Jpg, FileExtensions.Jpeg, FileExtensions.Jfi, FileExtensions.Jfif];

export function isJpg(extension: string): boolean {
    if (extension && jpgExtensions.some(x => x === extension.toLowerCase())) {
        return true;
    }

    return false;
}

export function isPng(extension: string): boolean {
    if (extension?.toLowerCase() === 'png') {
        return true;
    }

    return false;
}

export function getAllowedFileExtensions() {
    return [
        FileExtensions.Jpeg,
        FileExtensions.Jpg,
        FileExtensions.Jfi,
        FileExtensions.Jfif,
        FileExtensions.Png
    ];
}

export function getFileTypeFontAwesomeIcon(extension: string): string {
    switch (extension.toLowerCase()) {
        case FileExtensions.Csv: return 'fa-file-csv';
        case FileExtensions.Svg:
        case FileExtensions.Gif:
        case FileExtensions.Png:
        case FileExtensions.Jpg:
        case FileExtensions.Jfi:
        case FileExtensions.Jfif:
        case FileExtensions.Jpeg: return 'fa-file-image';
        case FileExtensions.Doc:
        case FileExtensions.Docx: return 'fa-file-word';
        case FileExtensions.Xlsx: return 'fa-file-spreadsheet';
        case FileExtensions.Pdf: return 'fa-file-pdf';
        case FileExtensions.Json: return 'fa-file-code';
        default: return 'fa-file';
    }
}