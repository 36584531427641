import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';

// store
import * as actions from 'app/authentication-v2/store/actions';

// models
import { User } from 'app/models/user.model';

// services
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { NavigationService } from 'app/shared/services/navigation.service';
import { UserService } from 'app/authentication-v2/services/user.service';
import { UserProfileService } from 'app/authentication-v2/services/user-profile.service';

@Injectable()
export class UserEffects {

    updateUserProfile$ = createEffect(() => this.actions$.pipe(
        ofType(actions.UpdateUserProfile),
        mergeMap((action) =>
            this.userService.userProfileUpdate$(action.request).pipe(
                map(response => actions.UpdateUserProfileSuccess({ response })),
                catchError(() => of(actions.UpdateUserProfileFail()))
            ))));

    setPin$ = createEffect(() => this.actions$.pipe(
        ofType(actions.SetPin),
        switchMap(action =>
            this.userProfileService.setPin$(action.pin, action.token).pipe(
                map((user: User) => actions.SetPinSuccess({ user })),
                catchError(() => of(actions.SetPinFail())))
        )));

    setPinSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(actions.SetPinSuccess),
        map(action => actions.SetToken({ token: action.user.token })),
    ));

    setPinFail$ = createEffect(() => this.actions$.pipe(
        ofType(actions.SetPinFail),
        tap(() => this.alertService.error('We were unable to set your PIN at this time.')),
    ), { dispatch: false });

    setMobileNumber$ = createEffect(() => this.actions$.pipe(
        ofType(actions.SetMobileNumber),
        switchMap(action =>
            this.userProfileService.setMobileNumber$(action.mobileNumber, action.diallingCode).pipe(
                map((result) => actions.SetMobileNumberSuccess({ confirmationId: result.confirmationId })),
                catchError(() => of(actions.SetMobileNumberFail())))
        )));

    setMobileNumberFail$ = createEffect(() => this.actions$.pipe(
        ofType(actions.SetMobileNumberFail),
        tap(() => this.alertService.error('We were unable to set your mobile number at this time.')),
    ), { dispatch: false });

    confirmOtp$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ConfirmOtp),
        switchMap(action =>
            this.userProfileService.confirmOtp$(action.code, action.confirmationId).pipe(
                map((result) => actions.ConfirmOtpSuccess({ user: result.user })),
                catchError(() => of(actions.ConfirmOtpFail())))
        )));

    confirmOtpSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ConfirmOtpSuccess),
        map(action => actions.SetToken({ token: action.user?.token })),
    ));

    confirmOtpFail$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ConfirmOtpFail),
        tap(() => this.alertService.error('We could not confirm the code.')),
    ), { dispatch: false });

    confirmPin$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ConfirmPin),
        switchMap(action =>
            this.userProfileService.confirmPin$(action.pin).pipe(
                map((result) => {
                    if (result.success) {
                        return actions.ConfirmPinSuccess({ user: result.user });
                    } else {
                        if (result.isLockedout) {
                            const minutesRemaining = Math.max(0, Math.ceil(result.lockoutSecondsRemaining / 60));
                            return actions.ConfirmPinFail({ message: `Your account has been locked, please wait ${minutesRemaining} minute${(minutesRemaining === 1 ? '' : 's')} and then try again.` });
                        } else {
                            const message = `Incorrect PIN entered. You have ${result.attemptsRemaining} more attempt${(result.attemptsRemaining === 1 ? '' : 's')} to log in before your account is locked for 15 minutes.`;
                            return actions.ConfirmPinFail({ message });
                        }
                    }
                }),
                catchError(() => of(actions.ConfirmPinFail({ message: 'There was an error signing you in, please check and try again.'}))))
        )));

    confirmPinSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ConfirmPinSuccess),
        map(action => actions.LoginSuccess({ user: action.user })),
    ));

    triggerSecurityReset$ = createEffect(() => this.actions$.pipe(
        ofType(actions.TriggerSecurityReset),
        switchMap(action =>
            this.userProfileService.triggerSecurityReset$(action.credential).pipe(
                map((result) => actions.TriggerSecurityResetSuccess({ confirmationId: result.confirmationId, notFound: result.notFound })),
                catchError(() => of(actions.TriggerSecurityResetFail())))
        )));

    triggerSecurityResetFail$ = createEffect(() => this.actions$.pipe(
        ofType(actions.TriggerSecurityResetFail),
        tap(() => this.alertService.error('We could not reset your security details.')),
    ), { dispatch: false });

    requestPinReset$ = createEffect(() => this.actions$.pipe(
        ofType(actions.RequestPinReset),
        switchMap(() =>
            this.userProfileService.requestPinReset$().pipe(
                map((result) => actions.RequestPinResetSuccess(result)),
                catchError(() => of(actions.RequestPinResetFail())))
        )));

    requestPinResetFail$ = createEffect(() => this.actions$.pipe(
        ofType(actions.RequestPinResetFail),
        tap(() => this.alertService.error('We could not request the PIN reset.')),
    ), { dispatch: false });

    requestPasswordReset$ = createEffect(() => this.actions$.pipe(
        ofType(actions.RequestPasswordReset),
        switchMap(() =>
            this.userProfileService.requestPasswordReset$().pipe(
                map((result) => actions.RequestPasswordResetSuccess(result)),
                catchError(() => of(actions.RequestPasswordResetFail())))
        )));

    requestPasswordResetFail$ = createEffect(() => this.actions$.pipe(
        ofType(actions.RequestPasswordResetFail),
        tap(() => this.alertService.error('We could not request the password reset.')),
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private userProfileService: UserProfileService,
        private alertService: AlertService,
        private navigation: NavigationService) { }
}
