<app-dialog
    id="pdf-preview-dialog"
    [dialogTitle]="dialogTitle"
    showClose="true"
    [fullScreen]="fullScreen"
    defaultHeight="1200px"
    defaultWidth="1000px">
    <div body class="overflow-hidden">
        <app-loading-card *ngIf="loading"></app-loading-card>
        <div *ngIf="error">There was an error retrieving the PDF.</div>
        <ngx-extended-pdf-viewer
                          [showOpenFileButton]="false"
                          [showDownloadButton]="downloadEnabled"
                          *ngIf="blobData"
                          [src]="blobData"
                          [filenameForDownload]="filename"
                          [page]="1"></ngx-extended-pdf-viewer>
    </div>

    <div footer-actions class="padding-top-8">
        <button *ngIf="blobData && downloadEnabled" class="btn large primary light" (click)="onDownloadClick()">Download</button>
        <button class="btn large primary light" mat-dialog-close>Close</button>
    </div>
</app-dialog>