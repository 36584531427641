import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { UserNotificationsDialogComponent } from 'app/connect/components/user-notifications-dialog/user-notifications-dialog.component';

// models
import { UserAlert } from 'app/connect/models/user-alert.model';
import { UserAlertGroup } from 'app/connect/models/user-alert-group.model';

// enums
import {
    getUserAlertCategoryTitle,
    UserAlertCategory,
} from 'app/connect/enums/user-alert-category.enum';

@Component({
    selector: 'app-user-alert-list',
    templateUrl: 'user-alert-list.component.html',
    styleUrls: ['./user-alert-list.component.scss'],
})
export class UserAlertListComponent extends BaseComponent implements OnInit {
    alertGroups: UserAlertGroup[];
    highlightedAlert$: Observable<UserAlert>;

    constructor(
        private dialogRef: MatDialogRef<UserNotificationsDialogComponent>,
        private store: Store<fromStore.NotificationState>,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.highlightedAlert$ = this.store.select(fromStore.getHighlightedAlert);

        this.store
            .select(fromStore.getUserAlerts)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((userAlerts) => this.createAlertGroups(userAlerts));
    }

    createAlertGroups(alerts: UserAlert[]) {
        if (!alerts || !alerts.length) {
            return [];
        }

        const groups: UserAlertGroup[] = [];

        for (const alert of alerts) {
            const existingGroup = groups.find(
                (group) => group.category === alert.category
            );

            if (existingGroup) {
                existingGroup.alerts.push(alert);
            } else {
                groups.push({
                    alerts: [alert],
                    category: alert.category,
                    title: getUserAlertCategoryTitle(alert.category),
                    collapsed:
                        this.alertGroups?.find(
                            (x) => x.category === alert.category
                        )?.collapsed ?? true,
                });
            }
        }

        this.alertGroups = groups;
    }

    readAlert(alert: UserAlert) {
        if (alert.read) {
            this.handleAlertPostReadAction(alert);
            return;
        }

        this.store.dispatch(fromStore.ReadUserAlert({ id: alert.id }));
        this.handleAlertPostReadAction(alert);
    }

    handleAlertPostReadAction(alert: UserAlert) {
        switch (alert.category) {
            case UserAlertCategory.Task:
                this.store.dispatch(fromStore.SetHighlightedAlert({ alert }));
                break;
            case UserAlertCategory.EntityImportComplete:
                this.redirect(alert, ['/portal/entities']);
                break;
            default:
                this.redirect(alert);
        }
    }

    redirect(alert: UserAlert, route: string[] = ['/portal', 'entities', alert.entityId]) {
        this.dialogRef.close();
        this.router.navigate(route);
    }

    dismissAlert(alert: UserAlert) {
        if (alert.dismissed) {
            return;
        }

        this.store.dispatch(fromStore.DismissUserAlert({ id: alert.id }));
    }

    toggleAlertGroup(category: UserAlertCategory) {
        this.alertGroups.forEach((group) => {
            if (group.category === category) {
                group.collapsed = !group.collapsed;
            } else {
                // Collapse other groups
                group.collapsed = true;
            }
        });
    }
}
