export enum ClientUserRequiredAction {
    None = 0,

    SetPin = 1,

    ProvidePin = 2,

    ProvideMobileNumber = 3,

    ConfirmMobileNumber = 4,

    ConfirmEmailAddress = 5,

    ProvidePassword = 6,

    SetPassword = 7,
}
