import { Injectable } from '@angular/core';

// models
import { Modules } from 'app/shared/enums/modules.enum';
import { User } from 'app/models/user.model';
import { Client } from 'app/models/client.model';

@Injectable()
export class ModuleService {

    private static client: Client;
    static isSuperUser: boolean = false;
    static isCredasUser = false;
    static isChargeableMode = false;

    static populate(user: User): void {
        ModuleService.isSuperUser = user.isSuperUser;
        ModuleService.isCredasUser = user.isCredasUser;
        ModuleService.isChargeableMode = user.chargeableMode;
    }

    static setClient(client: Client): void {
        ModuleService.client = client;
    }

    static getTitle(moduleId: Modules): string {
        return ModuleService.client?.userModules?.find(m => m.id === moduleId)?.title;
    }

    static getUrl(moduleId: Modules): string {
        return ModuleService.client?.userModules?.find(m => m.id === moduleId)?.url;
    }

    static isEnabled(moduleId: Modules, mustHaveTheModule: boolean = false): boolean {
        if (!ModuleService.client?.userModules) {
            if (!mustHaveTheModule) {
                return true;
            } else {
                return false;
            }
        }

        return this.client.userModules.find(m => m.id === moduleId) !== undefined;
    }
}