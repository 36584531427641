import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// ngrx | rxjs
import { catchError, first, Observable, of, takeUntil } from 'rxjs';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// services
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { FileSaverService } from 'ngx-filesaver';

export interface PdfPreviewDialogData {
    fullScreen?: boolean;
    dialogTitle?: string;
    downloadEnabled?: boolean;
    filename?: string;
    pdfBlob?: Blob;
    pdfBlobAsync$?: Observable<Blob>;
}

@Component({
    templateUrl: './pdf-preview-dialog.component.html'
})
export class PdfPreviewDialogComponent extends BaseComponent {
    loading = false;
    blobData: Blob;
    error = false;
    dialogTitle = 'Preview PDF';
    downloadEnabled = false;
    filename = 'document.pdf';
    fullScreen: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: PdfPreviewDialogData,
        private alertService: AlertService,
        private fileSaverService: FileSaverService) {
        super();

        data = data ?? {};

        if (data.dialogTitle) {
            this.dialogTitle = data.dialogTitle;
        }

        this.downloadEnabled = data.downloadEnabled === true;
        this.fullScreen = data.fullScreen ?? false;

        if (data.filename) {
            this.filename = data.filename;
        }

        if (data.pdfBlob) {
            this.blobData = data.pdfBlob;
        } else if (data.pdfBlobAsync$) {
            this.loading = true;
            data.pdfBlobAsync$.pipe(
                takeUntil(this.ngUnsubscribe),
                first(),
                catchError(() => {
                    this.alertService.error('Unable to retrieve the PDF document.');
                    this.error = true;
                    return of(null);
                }))
                .subscribe(blob => {
                    this.loading = false;
                    this.blobData = blob;
                    if (data.filename) {
                        this.filename = data.filename;
                    }
                });
        } else {
            throw new Error('Requires either a blob or an async blob.');
        }
    }

    onDownloadClick(): void {
        if (!this.blobData) {
            return;
        }
        this.fileSaverService.save(this.blobData, this.filename, this.blobData.type);
    }
}