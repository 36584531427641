// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { FilterConfiguration } from 'app/models/filter/filter-configuration.model';
import { Filter } from 'app/models/filter/filter.model';
import { CustomMenu } from 'app/models/custom-menu.model';

// enums
import { ViewType } from 'app/connect/enums/view-type.enum';
import { Modules } from 'app/shared/enums';

export const SetShowNewButton = createAction(
    '[Connect User Interface] Set show new button',
    props<{showNewButton: boolean; caption: string; icon: string}>()
);

export const NewButtonClicked = createAction(
    '[Connect User Interface] Trigger new button clicked',
);

export const NavigateToUrl = createAction(
    '[Connect User Interface] Navigate to page',
    props<{url: string}>()
);

export const OpenClientSelector = createAction(
    '[Connect User Interface] Open the client selector'
);

export const CloseClientSelector = createAction(
    '[Connect User Interface] Close the client selector'
);

export const OpenUserGroupSelector = createAction(
    '[Connect User Interface] Open the user group selector'
);

export const CloseUserGroupSelector = createAction(
    '[Connect User Interface] Close the user group selector'
);

export const OpenUserMenu = createAction(
    '[Connect User Interface] Open the user menu'
);

export const CloseUserMenu = createAction(
    '[Connect User Interface] Close the user menu'
);

export const ShowHeader = createAction(
    '[Connect User Interface] Show the header'
);

export const HideHeader = createAction(
    '[Connect User Interface] Hide the header'
);

export const ResetPage = createAction(
    '[Connect User Interface] Reset page'
);

export const SetPageViewType = createAction(
    '[Connect User Interface] Set page view type',
    props<{viewType: ViewType}>()
);

export const SetPageTitle = createAction(
    '[Connect User Interface] Set page title',
    props<{title: string}>()
);

export const SetShowShowMoreButton = createAction(
    '[Connect User Interface] Set show show more button',
    props<{showShowMoreButton: boolean}>()
);

export const SetShowMore = createAction(
    '[Connect User Interface] Set show more',
    props<{showMore: boolean}>()
);

export const SetAllowExport = createAction(
    '[Connect User Interface] Set whether to allow export',
    props<{allowExport: boolean}>()
);

export const SetAllowSwitchView = createAction(
    '[Connect User Interface] Set whether to allow switching view',
    props<{allowSwitchView: boolean}>()
);

export const SetPageFilterConfiguration = createAction(
    '[Connect User Interface] Set the page filter configuration',
    props<{filterConfiguration: FilterConfiguration}>()
);

export const SearchClicked = createAction(
    '[Connect User Interface] Trigger search button clicked',
    props<{filter: Filter}>()
);

export const ExportClicked = createAction(
    '[Connect User Interface] Trigger export button clicked'
);

export const ExportWithFilter = createAction(
    '[Connect User Interface] Trigger export with filter',
    props<{filter: Filter}>()
);

export const SetHideNavigation = createAction(
    '[Connect User Interface] Hide the navigation menus',
    props<{hideNavigation: boolean}>()
);

export const SetCustomMenus = createAction(
    '[Connect User Interface] Set custom menus',
    props<{menus: CustomMenu[]}>()
);

export const CustomMenuClicked = createAction(
    '[Connect User Interface] Custom menu clicked',
    props<{menu: CustomMenu}>()
);

export const SetReadOnlyAccess = createAction(
    '[Connect User Interface] Set read only access',
    props<{readOnlyAccess: boolean}>()
);

export const OpenHelpFaqSearch = createAction(
    '[Connect User Interface] Open the help faq search'
);

export const CloseHelpFaqSearch = createAction(
    '[Connect User Interface] Close the help faq search'
);

export const SetCanEditFoldersPermission = createAction(
    '[Connect User Interface] Set the permission to check for whether the user can edit folders',
    props<{permission: Modules}>()
);

export const SetCanDeleteFoldersPermission = createAction(
    '[Connect User Interface] Set the permission to check for whether the user can delete folders',
    props<{permission: Modules}>()
);

export const SetEntitySuperUserView = createAction(
    '[Connect User Interface] Set entity super user view',
    props<{superUserView: boolean}>()
);

export const SetClientLogoVisibility = createAction(
    '[Connect User Interface] Set client logo visibility',
    props<{ visible: boolean }>()
);

export const SetNavigationMenuVisibility = createAction(
    '[Connect User Interface] Set navigation menu visibility',
    props<{ visible: boolean }>()
);

export const SetUserProfileButtonVisibility = createAction(
    '[Connect User Interface] Set user profile button visibility',
    props<{ visible: boolean }>()
);