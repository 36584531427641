import { Component, Input } from '@angular/core';

// enums
import { LozengeVariant as LozengeVariant } from 'app/connect/enums/lozenge-variant.enum';

@Component({
    selector: 'app-lozenge',
    templateUrl: 'lozenge.component.html',
    styleUrls: ['lozenge.component.scss'],
})
export class LozengeComponent {
    @Input()
    text: string;

    @Input()
    textStyle: any;

    @Input()
    variant: LozengeVariant;

    @Input()
    showIcon: boolean = false;

    @Input()
    showDot: boolean = false;

    @Input()
    reverse: boolean = false;

    @Input()
    useLightColors: boolean = false;

    /**
     * Overrides the variant and applies
     * the style to the class attribute
     */
    @Input()
    style: string;

    getVariantStyle() {
        const classList = [];

        switch (this.variant) {
            case LozengeVariant.Amber:
                classList.push(
                    'alert',
                    this.useLightColors
                        ? 'rag-amber-light-bg text-gray-8'
                        : 'rag-amber-bg text-gray-8'
                );
                break;
            case LozengeVariant.Green:
                classList.push(
                    'positive',
                    this.useLightColors
                        ? 'rag-green-light-bg text-gray-8'
                        : 'rag-green-bg text-white'
                );
                break;
            case LozengeVariant.LightBlue:
                classList.push('neutral background-lightblue text-gray-8');
                break;
            case LozengeVariant.Primary:
                classList.push('neutral primary-bg primary-reverse-text');
                break;
            case LozengeVariant.Red:
                classList.push(
                    'negative',
                    this.useLightColors
                        ? 'rag-red-light-bg text-gray-8'
                        : 'rag-red-bg text-white'
                );
                break;
            case LozengeVariant.Black:
                classList.push('neutral background-gray-8 text-white');
                break;
            case LozengeVariant.Gray:
                classList.push('neutral background-gray-2 text-gray-8');
        }

        if (this.showIcon) {
            classList.push('has-icon');
        }

        if (this.reverse) {
            classList.push('reverse');
        }

        if (this.useLightColors) {
            classList.push('light');
        }

        return classList.join(' ');
    }

    getVariantIcon(): string {
        const classList = ['fa-solid', 'icon'];

        switch (this.variant) {
            case LozengeVariant.Amber:
                classList.push('fa-exclamation-circle action-required');
                break;
            case LozengeVariant.Green:
                classList.push('fa-circle-check pass');
                break;
            case LozengeVariant.Red:
                classList.push('fa-exclamation-traingle fail');
                break;
            case LozengeVariant.Primary:
            case LozengeVariant.Black:
            case LozengeVariant.Gray:
            case LozengeVariant.LightBlue:
            default:
                classList.push('fa-circle-ellipsis default');
        }

        return classList.join(' ');
    }
}
