<div fxFlex="1 1 auto"
     [class]="dialogClassList"
     [ngClass]="{ 'rag-amber-light-bg': state === 'working', 'rag-green-light-bg': state === 'success', 'rag-red-light-bg': state === 'failed' }"
     [ngStyle.gt-sm]="{ 'max-width': '100%', 'max-height': '100%', 'width': position === 'right' ? defaultWidth : null  }"
     [ngStyle.xs]="{ 'max-width': '100%', 'max-height': '100%', 'width': '100%', 'height': '100%' }">
    <div *ngIf="showHeader && state === 'default'"
         fxLayout="column"
         class="dialog-header"
         [class.header-bg]="useClientBranding"
         [class.padding-bottom-none]="noSubHeaderPadding"
         [class.shadow-none]="!!colorClass || !showHeaderShadow">
        <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
            <i *ngIf="dialogTitleIcon" class="fa icon-size-l" [ngClass]="dialogTitleIcon"></i>
            <button *ngIf="showBack" class="btn medium icon text-white background-gray-8 close-button"
                    (click)="Back.emit()">
                <i class="fa fa-solid fa-chevron-left"></i>
            </button>
            <div fxFlex="1 1 0%" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="space-between"
                 *ngIf="dialogTitle || dialogSubTitle">
                <h3 fxFlex class="dialog-header-title" [class.header-text]="useClientBranding">
                    {{dialogTitle ? dialogTitle : ' '}}
                </h3>
                <p *ngIf="dialogSubTitle" class="text-gray-8 font-size-s margin-bottom-none"
                   [class.header-text]="useClientBranding">
                    {{ dialogSubTitle }}
                </p>
            </div>
            <ng-content select="[header-content]" class="full-width"></ng-content>
            <div fxFlex *ngIf="!dialogTitle && !dialogSubTitle && showClose">&nbsp;</div>
            <button *ngIf="(handleCloseEvent || showClose) && state !== 'working'"
                    class="btn medium icon text-white background-gray-8 close-button"
                    [class.branded-dialog-close-button]="useClientBranding"
                    (click)="onCloseClick()"
                    data-auto="dialog-close-button">
                <i class="fa fa-solid fa-times" aria-label="close dialog"></i>
            </button>
        </div>
        <ng-content select="[sub-header]"></ng-content>
    </div>

    <div fxFlex
         class="dialog-content"
         [ngClass]="{ 'no-footer': !showFooterActions }"
         [ngStyle]="{ padding: contentPadding }"
         (scroll)="onContentScroll($event)">
        <ng-content *ngIf="state === 'default'" select="[body]"></ng-content>
        <div *ngIf="state !== 'default'"
             class="dialog-state-wrapper">
            <h3>
                <ng-content *ngIf="state === 'success'" select="[dialog-state-success-title]" />
                <ng-content *ngIf="state === 'failed'" select="[dialog-state-failure-title]" />
                <ng-content *ngIf="state === 'working'" select="[dialog-state-working-title]" />
            </h3>
            <div class="dialog-icon-wrapper"
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 [class.working]="state === 'working'"
                 [class.failed]="state === 'failed'">
                <i class="fa fa-solid icon-size-xxxl"
                   [ngClass]="{ 
                    'fa-arrows-rotate rag-amber-text fa-spin': state === 'working', 
                    'fa-circle-check rag-green-text': state === 'success', 
                    'fa-circle-exclamation rag-red-text': state === 'failed'
                }"></i>
            </div>
            <h4 class="font-weight-light align-center">
                <ng-content *ngIf="state === 'success'"
                            select="[dialog-state-success-body]"
                            class="align-center" />
                <ng-content *ngIf="state === 'failed'"
                            select="[dialog-state-failure-body]"
                            class="align-center" />
                <ng-content *ngIf="state === 'working'"
                            select="[dialog-state-working-body]"
                            class="align-center" />
            </h4>
        </div>
    </div>

    <div *ngIf="showFooterActions && state === 'default'"
         fxFlex="nogrow"
         fxLayoutAlign="end center"
         fxLayoutGap="10px"
         class="footer-wrapper {{colorClass}}">
        <div fxLayoutAlign="end center"
             fxLayoutGap="10px"
             class="footer-actions full-width"
             [ngStyle]="{ padding: footerPadding }">
            <ng-content select="[footer-actions]"></ng-content>
        </div>
    </div>
</div>