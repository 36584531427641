<app-brand-panel *ngIf="visible$ | async" id="client-selector"
                 [expandable]="true"
                 fxLayout="column"
                 fxLayoutGap="24px"
                 [showLogo]="false"
                 [showCloseButton]="showClose"
                 (closeClicked)="closeBrandPanel()">

    <div class="client-selector-backdrop"></div>
    <div fxLayout="column" fxLayoutAlign="centre center">
        <div class="title" fxLayout="column" fxLayoutGap="24px">
            <div class="title-text">Select Portal</div>
            
            <mat-form-field appearance="outline">
                <input matInput placeholder="Search" (input)="handleSearch(searchInput.value)" #searchInput/>
            </mat-form-field>
        </div>
    </div>

    <div fxFlex fxLayout="column" fxLayoutGap="24px" class="client-container">
        <div *ngIf="(user$ | async)?.clients?.length" fxLayout="column" fxLayoutGap="24px">
            <div fxLayout="row grid" fxLayoutGap="16px grid">
                <app-client-selector-option
                    fxFlex.lt-md="33%"
                    *ngFor="let client of filterClients((user$ | async).clients) | orderBy: 'name'"
                    [client]="client"
                    (click)="handleClientSelected(client)">
                </app-client-selector-option>
            </div>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="pointer back-link" (click)="handleBackLinkClicked()" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>chevron_left</mat-icon>
            <div>{{backLabel}}</div>
        </div>
    </div>
</app-brand-panel>